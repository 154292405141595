<template>
  <div id="certificates" class="section-wrapper">
    <div class="section">
      <h2>Earning Skills Certificates and Diplomas</h2>
      <p class="emphasized">
        A curriculum made up of skills identified by 2,000 finance teams around the world as
        critical for driving actionable insights and accelerating career growth.
      </p>
      <p class="paragraph">
        Complete groups of Competencies to earn Skills Certificates, and pass each Level to earn
        Diplomas. These milestones document your own progress and demonstrate tools you’ve acquired
        to employers. The CGMA Finance Leadership Program’s curriculum is based on the 2019 CIMA
        Syllabus, the world’s most up-to-date and technologically advanced accountancy program.
      </p>
      <p class="paragraph">
        Review the curriculum to see skills, Certificates and Diplomas you’ll earn throughout the
        program.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'competency-certification'
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  .section {
    padding: 3.5625rem 1.25rem 0;
    width: 940px;
    text-align: center;

    h2 {
      margin-bottom: 1.25rem;
    }

    @include media-lg {
      width: 100%;
    }
  }
}
</style>
