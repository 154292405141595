<template>
  <div @click="$emit('close')" class="hamburger-menu-overlay">
    <div @click.stop class="hamburger-menu">
      <div class="actions">
        <img alt="CGMA logo" src="../../../assets/img/aicpa_cima_logo_color.png" class="logo">
        <button
          @click="$emit('goToEnrollment')"
          class="brand-btn btn-purple btn-rounded get-started">
          ENROLL NOW
        </button>
        <button @click="$emit('close')" class="brand-btn btn-close" aria-label="Close menu">
          <font-awesome-icon :icon="['far', 'times']" />
        </button>
      </div>
      <ul class="menu">
        <li v-for="{ label, elementId, children } in links" :key="label" class="menu-item">
          <sub-menu
            v-if="children && children.length"
            @scrollTo="scrollTo($event)"
            :label="label"
            :links="children" />
          <a v-else @click="scrollTo(elementId)" class="menu-link">{{ label }}</a>
        </li>
      </ul>
      <hr class="horizontal-divider">
      <a href="mailto: flp@aicpa-cima.com" class="contact-us-link">Contact Us</a>
    </div>
  </div>
</template>

<script>
import SubMenu from './SubMenu';

const SCROLL_OPTIONS = { offset: 80 };

export default {
  name: 'hamburger-menu',
  data() {
    return {
      links: [
        { label: 'Program overview', elementId: '#page-header' },
        {
          label: 'Learning experience',
          elementId: '#designation',
          children: [
            { label: 'How it works', elementId: '#howItWorks' },
            { label: 'Earn the CGMA designation', elementId: '#designation' },
            { label: 'Certificates and curriculum', elementId: '#certificates' },
            { label: 'Case Study Exams', elementId: '#CSE' }
          ]
        },
        { label: 'Gateway & subscription options', elementId: '#gateways' },
        { label: 'Membership', elementId: '#membership' },
        { label: 'Employers & Universities', elementId: '#employersAndUniversities' }
      ]
    };
  },
  methods: {
    scrollTo(id) {
      this.$vuetify.goTo(id, SCROLL_OPTIONS);
      this.$emit('close');
    }
  },
  components: {
    SubMenu
  }
};
</script>

<style lang="scss" scoped>
.hamburger-menu-overlay {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 10;
}

.hamburger-menu {
  width: 25rem;
  height: 100%;
  padding: 0 2.625rem;
  background-color: #FFFFFF;

  .logo {
    display: none;
    width: 10.875rem;

    @include media-sm {
      display: block;
      padding: 0.625rem 0;
    }
  }

  .btn-close {
    position: absolute;
    top: 0.9375rem;
    right: 0.5625rem;
  }

  .link-accordion span,
  a {
    font-size: 1.125rem;
    line-height: 2rem;
    color: $text-color;
    text-decoration: none;
  }

  .link-accordion {
    span {
      margin-right: 0.3125rem;
    }

    .dropdown-icon {
      font-size: .9rem;
    }

    .menu {
      padding-left: 0.625rem;

      .menu-item {
        font-weight: 300;

        &:first-child {
          margin-top: 0.625rem;
        }
      }
    }
  }

  .actions {
    text-align: center;

    .get-started {
      margin: 1.25rem auto;
      font-weight: 400;
      white-space: nowrap;
    }
  }

  .menu {
    padding: 0;
    list-style: none;

    .menu-item {
      margin-bottom: 0.9375rem;

      .menu-link {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .horizontal-divider {
    margin-left: 0;
  }

  .contact-us-link, .log-out {
    color: $brand-purple;
    font-weight: 300;
  }

  .log-out {
    display: block;
    margin-top: 0.75rem;
    font-weight: 400;
  }

  @include media-lg {
    padding: 0 1.25rem;
  }

  @include media-sm {
    ::v-deep .modal-container {
      width: 100%;
    }
  }
}
</style>
