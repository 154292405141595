<template>
  <accordion title="Operational">
    <ul class="accordion-content">
      <li class="subtext">
        The Operational Level teaches essential business concepts and establishes a strong
        foundation in technical finance and accounting.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_certificate_small.png" class="icon" alt="Certificate icon">
        Digital Finance Skills Certificate
      </li>
      <li class="content-item">
        <span class="subtitle">
          Finance Function Transformation
        </span>
        Understand the role and transformation of finance.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Finance Business Partnering
        </span>
        Explain main business functions and finance business partnering.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Data and Analytics
        </span>
        Identify how data-backend analysis supports decision making.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_certificate_small.png" class="icon" alt="Certificate icon">
        Budget and Cost Analysis Skills Certificate
      </li>
      <li class="content-item">
        <span class="subtitle">
          Short-Term Decision Making
        </span>
        Present information to support decision making that considers uncertainty.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Cost Accounting
        </span>
        Apply cost accounting methods to planning, control and reporting.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Budgeting and Planning
        </span>
        Prepare and use budgets to impact planning and control.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_certificate_small.png" class="icon" alt="Certificate icon">
        Finance Reporting Skills Certificate
      </li>
      <li class="content-item">
        <span class="subtitle">
          Regulatory Environment
        </span>
        Describe regulatory oversight, standard setting and corporate governance processes.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Finance Statements
        </span>
        Apply financial reporting standards to prepare financal statements.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Principles of Taxation
        </span>
        Recognize key international taxation issues and prepare corporate tax calculations.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Managing Working Capital
        </span>
        Utilize short-term financing and working capital management techniques.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_diploma_small.png" class="icon" alt="Diploma icon">
        Diploma in Management Accounting
      </li>
      <li class="content-item">
        Receive your diploma after completing the Operational Learning Level and the Operational Case Study.
      </li>
    </ul>
  </accordion>
</template>

<script>
import Accordion from 'student/components/common/Accordion';

export default {
  name: 'operational',
  components: {
    Accordion
  }
};
</script>
