<template>
  <div class="item streamline-development">
    <img src="../../../../assets/img/AICPA__Icon_Designation_medal.png" alt="Ribbon">
    <div class="title">Streamline professional development</div>
    <p class="content">
      Integrated Learning and Assessment — Gain instant access to everything you need to
      complete the program. All learning, exams, prep courses and fees are included in the
      price and can be completed online at your convenience.
    </p>
  </div>
</template>

<script>
export default {
  name: 'streamline-development'
};
</script>
