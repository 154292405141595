<template>
  <div id="membership" class="section-wrapper">
    <div class="background"></div>
    <div class="section">
      <img src="../../assets/img/Transparent_shape_overlay.png" class="swoosh" alt="Title overlay">
      <h2>Join other leaders in global finance</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fellow-leaders'
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  position: relative;
  height: 390px;
  overflow: hidden;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 700px;
    background-image: url('../../assets/img/AICPA_fellow_leaders.jpg');
    background-repeat: no-repeat;
    background-position: 50% 45%;
    background-size: cover;
  }

  .section {
    position: relative;
    z-index: 10;

    .swoosh {
      position: absolute;
      width: 2300px;
      top: -235px;
      left: -830px;
      z-index: 1;
    }

    h2 {
      position: relative;
      z-index: 10;
      margin-top: 20px;
      padding-left: 20px;
      color: $darker-gray;
    }
  }

  @include media-lg {
    .background {
      background-position: bottom;
      top: -100px;
    }

    .section {
      h2 {
        margin-top: 10px;
        padding-left: 20px;
      }
    }

    .swoosh {
      width: 1900px;
      height: 260px;
      top: -140px;
      left: -890px;
    }
  }

  @include media-sm {
    height: 230px;

    .background {
      top: 0px;
      height: 230px;
    }

    .section {
      h2 {
        font-size: 1.25rem;
        line-height: 1.875rem;
      }
    }
  }
}
</style>
