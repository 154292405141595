<template>
  <div :class="{ transparent }" :style="style" class="section-wrapper">
    <v-alert
      v-show="isAlertEnabled"
      ref="alert"
      @input="showAlert = false"
      color="#72246C"
      class="rounded-0"
      dismissible
      dark>
      Please note: CGMA Finance Leadership Program registration will be unavailable
      from April 19-23 for migration to a new system, after which a new registration process will be launched.
      The current Finance Leadership Program platform will remain accessible throughout this window,
      and existing candidates can continue to use your existing account and password.
    </v-alert>
    <div class="section">
      <div class="header">
        <img alt="AICPA & CIMA logo" src="../../assets/img/aicpa_cima_logo_color.png" class="logo">
        <div class="header-right">
          <button
            @click="$emit('goToEnrollment')"
            :class="{ 'btn-purple': !transparent }"
            class="brand-btn btn-rounded get-started">
            ENROLL NOW
          </button>
          <font-awesome-icon
            @click="showMenu = !showMenu"
            :icon="['far', 'bars']"
            class="hamburger-menu-icon" />
        </div>
      </div>
      <hamburger-menu
        @close="showMenu = false"
        @goToEnrollment="$emit('goToEnrollment')"
        :class="{ open: showMenu }"
        class="menu" />
    </div>
  </div>
</template>

<script>
import HamburgerMenu from '../common/HamburgerMenu';
import { showDeprecationAlert } from 'config/shared/environment';
import { TABLET_MAX_WIDTH } from 'student/config';
import throttle from 'lodash/throttle';

export default {
  name: 'top-menu',
  data() {
    return {
      showMenu: false,
      showAlert: true,
      alertHeight: 0,
      transparent: true
    };
  },
  computed: {
    isAlertEnabled() {
      return showDeprecationAlert;
    },
    style() {
      const NAVBAR_HEIGHT = 80;
      const height = this.showAlert
        ? this.alertHeight + NAVBAR_HEIGHT
        : NAVBAR_HEIGHT;
      return { height: height + 'px' };
    }
  },
  methods: {
    throttledTransparencyScroll: throttle(function () {
      const offset = window.innerWidth > TABLET_MAX_WIDTH ? 350 : 50;
      this.transparent = window.pageYOffset < offset;
    }, 100)
  },
  mounted() {
    window.addEventListener('scroll', this.throttledTransparencyScroll);
    this.alertHeight = this.$refs.alert.$el.clientHeight;
  },
  destroyed() {
    window.removeEventListener('scroll', this.throttledTransparencyScroll);
  },
  components: {
    HamburgerMenu
  }
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  position: fixed;
  top: 0;
  z-index: 20;
  height: 5rem;
  max-width: 100vw;
  color: $text-color;
  background: #FFFFFF;
  box-shadow: -1px 2px 7px 0 rgba(0,0,0,0.28);
  transition: .3s;

  &.transparent {
    color: #FFFFFF;
    background: transparent;
    box-shadow: none;

    .logo {
      filter: contrast(0) brightness(100);
    }
  }

  ::v-deep > .v-alert {
    margin-bottom: 0rem;
    padding: 1rem 1.5rem;
  }
}

.menu {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  transition: .2s;

  &.open {
    right: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;

  .logo {
    width: 10.875rem;
  }

  .header-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 14.375rem;

    .hamburger-menu-icon {
      padding: 0.3125rem;
      font-size: 1.6rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@include media-sm {
  .header {
    .logo {
      width: 6rem;
    }

    .header-right {
      width: 2.1875rem;

      .get-started {
        display: none;
      }
    }
  }

  .section-wrapper ::v-deep .modal-container {
    width: 100%;
    height: 100%;
    justify-items: center;
  }
}
</style>
