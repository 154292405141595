<template>
  <div id="packages" class="section-wrapper">
    <div class="section">
      <h2>Flexible packages based on your goals and schedule</h2>
      <p class="emphasized">Choosing the right plan for you...</p>
      <p class="content">
        The Finance Leadership Program builds in-demand skills across four Levels:
        Foundational, Operational, Management and Strategic. Advance from elemental
        business principles to the most advanced strategic topics to take on critical
        business issues impacting finance today. Then go beyond competency development
        and prepare for the case study exams to earn CGMA Designation with the Case
        Study Review Course.
      </p>
      <p class="content">
        Skills Plus and Skills Premium include everything you need to build your proficiencies,
        but Skills Premium includes additional features to help you fast-track through your studies.
        You'll receive expert tuition throughout the competency learning and case study preparation
        and are fully supported without losing the flexibility our program offers.
      </p>
      <p class="content">
        Some or all subscription options may not be available in all markets. To see what options
        are available to you, click 'Enroll Now'.
      </p>
    </div>
    <cgma-packages />
  </div>
</template>

<script>
import CgmaPackages from 'student/components/common/Packages';
import { SKILLS_PLUS_PACKAGE } from 'globalCommon/packages';

export default {
  name: 'home-packages',
  data() {
    return {
      SKILLS_PLUS_PACKAGE
    };
  },
  components: {
    CgmaPackages
  }
};
</script>

<style lang="scss" scoped>
.section {
  text-align: center;

  h2 {
    margin: 20px auto;
    width: 730px;
  }

  .content {
    margin: 0 auto;
    width: 900px;
    font-size: 1.125rem;
    line-height: 2rem;
  }

  @include media-lg {
    h2 {
      width: 100%;
    }

    .content {
      width: 100%;
    }
  }
}
</style>
