<template>
  <div class="section-wrapper">
    <div class="section">
      <h2>The CGMA Finance Leadership Program helps you:</h2>
      <slider v-if="isTablet" :slide-count="2" class="incentives">
        <div class="slides-group">
          <personalized-journey />
          <accelerate-growth />
          <develop-skills />
        </div>
        <div class="slides-group">
          <streamline-development />
          <designation />
        </div>
      </slider>
      <!-- eslint-disable-next-line vue/require-component-is -->
      <component v-else v-bind="nonTabletLayout" class="incentives">
        <personalized-journey />
        <accelerate-growth />
        <develop-skills />
        <streamline-development />
        <designation />
      </component>
      <p class="eligibility-assessment">
        The CGMA Finance Leadership Programme is open to anyone to join however,
        the Eligibility Assessment determines your starting point on the
        qualification journey and allows students with relevant qualifications
        to receive exemptions where relevant.
      </p>
    </div>
  </div>
</template>

<script>
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from 'student/config';
import AccelerateGrowth from './incentives/AccelerateGrowth';
import Designation from './incentives/Designation';
import DevelopSkills from './incentives/DevelopSkills';
import PersonalizedJourney from './incentives/PersonalizedJourney';
import Slider from '../../common/Slider';
import StreamlineDevelopment from './incentives/StreamlineDevelopment';

export default {
  name: 'finance-leadership',
  data() {
    return {
      isMobile: false,
      isTablet: false
    };
  },
  computed: {
    nonTabletLayout() {
      if (this.isMobile) return { is: 'slider', slideCount: 5 };
      return { is: 'div' };
    }
  },
  methods: {
    calculateSize() {
      this.isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;
      this.isTablet = window.innerWidth > MOBILE_MAX_WIDTH && window.innerWidth <= TABLET_MAX_WIDTH;
    }
  },
  mounted() {
    window.addEventListener('resize', this.calculateSize);
    this.calculateSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateSize);
  },
  components: {
    AccelerateGrowth,
    Designation,
    DevelopSkills,
    PersonalizedJourney,
    Slider,
    StreamlineDevelopment
  }
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  height: 44.875rem;
  background-color: $influential-green;

  .section {
    padding-top: 3.5625rem;
    text-align: center;
    color: white;

    h2 {
      margin-bottom: 60px;
      color: white;
    }

    .incentives {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      ::v-deep .item {
        width: 230px;
        margin: 0 10px;
        text-align: center;

        &.accelerate-growth {
          width: 214px;

          .title {
            width: 176px;
          }
        }

        &.develop-skills {
          width: 222px;

          .title {
            width: 160px;
          }
        }

        &.streamline-development {
          width: 222px;

          .title {
            width: 211px;
          }
        }

        img {
          margin: 15px auto;
          height: 65px;
        }

        .title {
          margin: 0 auto;
          width: 200px;
          font-weight: 500;
          font-size: 1.1875rem;
        }

        .content {
          font-size: .875rem;
          line-height: 1.3125rem;
        }
      }
    }

    .eligibility-assessment {
      margin: 30px auto;
      max-width: 826px;
      width: 100%;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 2rem;
    }
  }

  @include media-lg {
    overflow: hidden;
    height: auto;

    .section {
      h2 {
        margin: 0 auto;
        width: 90%;
        font-size: 1.4rem;
      }

      .incentives {
        display: block;
        margin-top: 20px;

        .slides-group {
          display: flex;
          justify-content: center;
          overflow: hidden;

          .item {
            width: 25%;
            margin: 0 25px;

            ::v-deep .title {
              width: 100%;
            }
          }
        }

        ::v-deep  .navigation {
          .navigation-item {
            background: rgba(255, 255, 255, .5);
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);

            &.active {
              background-color: white;
            }
          }
        }
      }

      .eligibility-assessment {
        width: 90%;
        font-size: 1.1875rem;
      }
    }
  }

  @include media-sm {
    .section {
      .incentives {
        .item {
          width: calc(100vw - 100px) !important;
          padding: 0 20px;

          ::v-deep .title {
            width: 100%!important;
          }
        }
      }
    }
  }
}
</style>
