<template>
  <div :class="{ recommendation }" class="total-price">
    <div v-show="showOriginalPrice" class="price original-price">
      <span class="currency-sign">{{ selectedCurrencySign }}</span>
      {{ selectedPrice }}
    </div>
    <div class="price">
      <span class="currency-sign">{{ finalPrice ? selectedCurrencySign : '' }}</span>
      <span v-if="finalPrice">{{ finalPrice.toLocaleString('en-US') }}</span>
      <span class="price-disclaimer">*</span>
    </div>
    <div v-if="recommendation" class="currency-label">
      {{ selectedCurrencyLabel }}
    </div>
    <div v-else class="currency-select">
      <cgma-dropdown v-model="currency" :options="currencies" />
    </div>
  </div>
</template>

<script>
import CgmaDropdown from 'student/components/common/CgmaDropdown';
import { CURRENCIES } from 'config/shared/currencies';

export default {
  name: 'package-total-price',
  props: {
    selectedPrices: { type: Object, default: null },
    recommendation: { type: Object, default: null }
  },
  data() {
    return {
      currency: CURRENCIES[this.recommendation ? this.recommendation.currency : 'GBP'].value
    };
  },
  computed: {
    currencies() {
      return Object.values(CURRENCIES);
    },
    selectedPrice() {
      const { selectedPrices, currency } = this;
      return selectedPrices && selectedPrices[currency];
    },
    selectedCurrencySign() {
      return CURRENCIES[this.currency].sign;
    },
    selectedCurrencyLabel() {
      return CURRENCIES[this.currency].label;
    },
    finalPrice() {
      const { discount } = this.recommendation || {};
      if (!discount) return this.selectedPrice;

      const finalPercentage = 1 - (discount / 100);
      const finalPrice = finalPercentage * this.selectedPrice;
      return finalPrice.toFixed(2);
    },
    showOriginalPrice() {
      const { discount } = this.recommendation || {};
      if (discount === 100) return false;
      return this.finalPrice !== this.selectedPrice;
    }
  },
  components: {
    CgmaDropdown
  }
};
</script>

<style lang="scss" scoped>
.total-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 4.375rem;
  padding: 0 1.25rem;
  background: $pink;
  border: 1px solid $brand-purple;
  box-sizing: border-box;

  .price, .currency-label {
    display: flex;
    align-items: center;
    margin-right: 10%;
    font-size: 1.75rem;
    font-weight: 500;
    color: $brand-purple;

    .currency-sign {
      margin-right: 0.625rem;
    }

    .price-disclaimer {
      margin-top: -0.625rem;
      font-size: 1.5rem;
    }

    &.original-price {
      margin-right: 0.625rem;
      color: #000000;
      font-weight: 300;
      text-decoration: line-through;
    }
  }

  &.recommendation {
    justify-content: center;

    .currency-label {
      margin-right: 0;
    }

    .price {
      margin-right: 4%;
    }
  }
}

.currency-select {
  width: 5.625rem;
  height: 2.8125rem;

  ::v-deep .select {
    padding: 0 1.125rem;
  }

  ::v-deep .icon {
    right: 1.125rem;
  }
}
</style>
