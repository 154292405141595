import { render, staticRenderFns } from "./LearningExperience.vue?vue&type=template&id=058dbe68&scoped=true&"
import script from "./LearningExperience.vue?vue&type=script&lang=js&"
export * from "./LearningExperience.vue?vue&type=script&lang=js&"
import style0 from "./LearningExperience.vue?vue&type=style&index=0&id=058dbe68&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058dbe68",
  null
  
)

export default component.exports