import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'faSvg',
    values: {
      clear: { component: FontAwesomeIcon, props: { icon: ['fal', 'times'] } }
    }
  },
  theme: {
    themes: {
      light: {
        'brand-purple': '#72246C',
        'influential-green': '#00857D',
        'light-gray': '#BBBCBC',
        'dark-gray': '#63666A',
        'failure-red': '#E00600'
      }
    }
  }
});
