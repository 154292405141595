<template>
  <div class="item">
    <img src="../../../../assets/img/AICPA__Icon_AICPAmark.png" alt="CGMA logo">
    <div class="title">Earn CGMA Designation</div>
    <p class="content">
      Showcase Your Value — Join 150,000 designation holders and the world’s largest
      community of finance professionals.
    </p>
  </div>
</template>

<script>
export default {
  name: 'designation'
};
</script>
