<template>
  <div ref="videoWrapper" class="brightcove-wrapper">
    <div ref="videoElement" class="video-js"></div>
  </div>
</template>

<script>
import brightcovePlayerLoader from '@brightcove/player-loader';

export default {
  name: 'brightcove-video',
  props: {
    accountId: { type: String, required: true },
    playerId: { type: String, required: true },
    videoId: { type: String, required: true }
  },
  methods: {
    createPlayer() {
      brightcovePlayerLoader({
        refNode: this.$refs.videoElement,
        accountId: this.accountId,
        playerId: this.playerId,
        videoId: this.videoId
      })
        .then(video => video.ref.play());
    }
  },
  mounted() {
    this.createPlayer();
  },
  beforeDestroy() {
    brightcovePlayerLoader.reset();
  }
};
</script>

<style lang="scss" scoped>
.brightcove-wrapper {
  height: 80vh;

  ::v-deep {
    .video-js:first-child {
      height: 80vh;
      width: auto;
    }
  }
}
</style>
