<template>
  <div @click="$emit('close')" class="modal-overlay">
    <div @click.stop class="modal-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal'
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(0, 0, 0, .5);

  .modal-container {
    width: 80%;
    padding: 10px;
    background-color: white;
  }
}
</style>
