<template>
  <div class="section-wrapper">
    <div class="section">
      <h2>The CGMA Finance Leadership Program learning experience</h2>
      <div class="content">
        <p class="emphasized">
          Real world business simulations build and strengthen your finance,
          business and leadership skills.
        </p>
        <p class="paragraph">
          The Finance Leadership Program is built on the critical knowledge that finance experts
          determined you need in order to advance your career (outlined in their
          <a
            href="https://www.aicpa-cima.com/resources/landing/cimas-cgma-professional-qualification"
            target="_blank">
            2019 CIMA Syllabus</a>).
          The program supports the development and demonstration of the highest levels of
          competency in finance, accounting, business and leadership skills that will help you
          advance your career and add value to your company. Your personal qualifications and
          educational background determine where you begin, the order in which you progress
          and your timeline for completing the program.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'learning-experience'
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  .section {
    padding: 3.5625rem 1.25rem;
    max-width: 920px;
    text-align: center;

    a {
      color: $brand-purple;
    }

    h2 {
      margin-bottom: 1.25rem;
    }
  }
}
</style>
