'use strict';

const { recordOwners: { CIMAFLP } } = require('./registration');

const CURRENCIES = {
  USD: { label: 'USD', value: 'USD', sign: '$' },
  GBP: { label: 'GBP', value: 'GBP', sign: '£' }
};

function getDefaultCurrency(recordOwner) {
  return recordOwner === CIMAFLP ? CURRENCIES.GBP.value : CURRENCIES.USD.value;
}

module.exports = {
  getDefaultCurrency,
  CURRENCIES
};
