<template>
  <accordion title="Foundational">
    <ul class="accordion-content">
      <li class="subtext">
        The Foundational Level is a condensed introduction to the concepts of business and finance.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_certificate_small.png" class="icon" alt="Certificate icon">
        Business and Finance Essentials Skills Certificate
      </li>
      <li class="content-item">
        <span class="subtitle">
          Introduction to Business and Finance
        </span>
        Understand how a business functions and the wider business environment
      </li>
      <li class="content-item">
        <span class="subtitle">
          Introduction to Financial Accounting
        </span>
        Describe the financial accounting process and how financial statements are prepared
      </li>
      <li class="content-item">
        <span class="subtitle">
          Introduction to Management Accounting
        </span>
        Explain the role of management accounting and how it supports relevant decision-making
      </li>
    </ul>
  </accordion>
</template>

<script>
import Accordion from 'student/components/common/Accordion';

export default {
  name: 'foundational',
  components: {
    Accordion
  }
};
</script>
