'use strict';

const TYPES = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  TEST: 'test'
};

const env = process.env.NODE_ENV || TYPES.DEVELOPMENT;

const parseList = (list = '') => list.split(',').map(it => it.trim());
const generateAction = (action, actionPrefix) => {
  const prefix = actionPrefix || process.env.SIEBEL_ACTION_PREFIX;
  return `${prefix}${action}`;
};

module.exports = {
  isDevEnv: env === TYPES.DEVELOPMENT,
  isProdEnv: env === TYPES.PRODUCTION,
  isTestEnv: env === TYPES.TEST,
  port: {
    client: Number(process.env.CLIENT_PORT) || 6101,
    server: Number(process.env.SERVER_PORT) || 6100
  },
  defaultPageTitle: 'CGMA Finance Leadership Program',
  rootApiPath: process.env.ROOT_API_PATH || '/api/v1/',
  database: {
    url: {
      common: process.env.POSTGRES_URI,
      test: process.env.POSTGRES_TEST_URI
    }
  },
  auth: {
    token: {
      expiresIn: process.env.AUTH_JWT_TOKEN_EXPIRES_IN || '1d',
      adminExpiresIn: process.env.ADMIN_AUTH_JWT_TOKEN_EXPIRES_IN || '5d',
      adminTempExpiresIn: process.env.ADMIN_AUTH_TEMP_JWT_TOKEN_EXPIRES_IN || '15m'
    },
    impersonation: {
      expiresIn: process.env.IMPERSONATION_EXPIRES_IN || '10m'
    }
  },
  integration: {
    printXmlRequests: process.env.INTEGRATION_PRINT_XML_REQUESTS === 'true',
    okta: {
      url: process.env.OKTA_BASE_URL,
      token: process.env.OKTA_API_TOKEN,
      apps: parseList(process.env.OKTA_APPS)
    },
    siebel: {
      auth: {
        username: process.env.SIEBEL_USERNAME,
        password: process.env.SIEBEL_PASSWORD
      },
      urls: {
        default: process.env.SIEBEL_URL,
        checkout: process.env.SIEBEL_CHECKOUT_URL
      },
      hash: {
        key: process.env.SIEBEL_CHECKOUT_ID_HASH_KEY,
        iv: process.env.SIEBEL_CHECKOUT_ID_HASH_IV
      },
      actions: {
        convertContact: generateAction(process.env.SIEBEL_CONVERT_CONTACT_ACTION),
        createAccount: generateAction(process.env.SIEBEL_CREATE_ACCOUNT_ACTION),
        dedupe: generateAction(process.env.SIEBEL_DEDUPE_ACTION),
        entry: generateAction(process.env.SIEBEL_ENTRY_ACTION),
        countryCodes: generateAction(process.env.SIEBEL_COUNTRY_CODES_ACTION),
        exemptions: generateAction(process.env.SIEBEL_EXEMPTION_ACTION),
        getWebProfile: generateAction(process.env.SIEBEL_GET_WEB_PROFILE_ACTION),
        voucher: generateAction(process.env.SIEBEL_VOUCHER_ACTION),
        createQuote: generateAction(process.env.SIEBEL_CREATE_QUOTE_ACTION),
        updateContact: process.env.SIEBEL_UPDATE_CONTACT_ACTION
      }
    },
    soa: {
      auth: {
        username: process.env.SOA_USERNAME,
        password: process.env.SOA_PASSWORD
      },
      urls: {
        dedup: process.env.SOA_DEDUP_URL
      },
      actions: {
        dedup: process.env.SOA_DEDUP_ACTION
      }
    },
    dnb: {
      auth: {
        username: process.env.DNB_USERNAME,
        password: process.env.DNB_PASSWORD
      },
      urls: {
        default: process.env.DNB_URL
      },
      actions: {
        getEmployers: process.env.DNB_GET_EMPLOYERS_ACTION
      }
    },
    slack: {
      enabled: process.env.ENABLE_SLACK_NOTIFICATIONS,
      webhookUrl: process.env.SLACK_WEBHOOK_URL
    }
  },
  cron: {
    sync: {
      expression: process.env.SYNC_CRON_EXPRESSION
    },
    user: {
      expression: process.env.USER_CRON_EXPRESSION,
      timeOffset: Number(process.env.USER_REMOVAL_TIME_OFFSET_IN_DAYS || 7)
    },
    token: {
      expression: process.env.TOKEN_CRON_EXPRESSION
    }
  },
  email: {
    address: process.env.EMAIL_ADDRESS,
    user: process.env.EMAIL_USER,
    password: process.env.EMAIL_PASSWORD,
    host: process.env.EMAIL_HOST,
    skipSending: process.env.SKIP_SENDING_EMAILS === 'true',
    port: process.env.EMAIL_PORT,
    ssl: process.env.EMAIL_SSL,
    tls: process.env.EMAIL_TLS,
    transitionNotificationAddress: process.env.TRANSITION_EMAIL_ADDRESS
  },
  membershipUrl: process.env.VUE_APP_BECOME_A_MEMBER_URL,
  showSkillsCoreOnPip: process.env.VUE_APP_SHOW_SKILLS_CORE_ON_PIP === 'true',
  showDeprecationAlert: process.env.VUE_APP_SHOW_DEPRECATION_ALERT === 'true'
};
