<template>
  <accordion title="Strategic">
    <ul class="accordion-content">
      <li class="subtext">
        In the Strategic Level, you’ll complete your finance professional skill set with new
        insights and a valuable toolkit to help you lead at the enterprise level.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_certificate_small.png" class="icon" alt="Certificate icon">
        Strategy Management Skills Certificate
      </li>
      <li class="content-item">
        <span class="subtitle">
          Competitive Advantage
        </span>
        Advise on current position and long-term direction.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Strategy Formulation
        </span>
        Formulate and effectively present strategy to leadership.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Strategy Implementation
        </span>
        Evaluate the effectiveness of strategy implementation and the importance of change management.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Digital Strategy
        </span>
        Develop and implement enterprise-wide digital transformation.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_certificate_small.png" class="icon" alt="Certificate icon">
        Enterprise Risk Management Skills Certificate
      </li>
      <li class="content-item">
        <span class="subtitle">
          Enterprise Risk Management
        </span>
        Identify, evaluate and manage enterprise risks.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Strategic Risk
        </span>
        Analyse risks associated with governance and strategy formulation.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Internal Control
        </span>
        Recommend and implement internal controls.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Cybersecurity
        </span>
        Anticipate cybersecurity threats and apply risk management techniques.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_certificate_small.png" class="icon" alt="Certificate icon">
        Corporate Finance Strategy Skills Certificate
      </li>
      <li class="content-item">
        <span class="subtitle">
          Financial Policies and Objectives
        </span>
        Evaluate strategic goals and policy decisions.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Debt and Equity Finance
        </span>
        Analyse capital structure and strategic financing decisions.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Financial Risk
        </span>
        Assess and manage financial risks.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Business Valuation and Acquisitions
        </span>
        Evaluate decisions impacting business valuation and acquisitions
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_diploma_small.png" class="icon" alt="Diploma icon">
        Chartered Global Management Accountant (CGMA) Designation
      </li>
      <li class="content-item">
        After completing Strategic Learning Level and the Strategic Case Study, and with three or
        more years of verified relevant work-based practical experience, you can become a CGMA.
      </li>
    </ul>
  </accordion>
</template>

<script>
import Accordion from 'student/components/common/Accordion';

export default {
  name: 'strategic',
  components: {
    Accordion
  }
};
</script>
