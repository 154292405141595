<template>
  <button
    @click="$emit('click')"
    :class="classes"
    :disabled="loading || disabled">
    <font-awesome-icon v-if="loading" :icon="['fas', 'spinner-third']" spin />
    <template v-else>{{ label }}</template>
  </button>
</template>

<script>
export default {
  name: 'loading-button',
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    classes: { type: String, default: 'brand-btn btn-large btn-purple' },
    label: { type: String, required: true }
  }
};
</script>
