<template>
  <div class="slide">
    <div class="slide-content">
      <h2>{{ title }}</h2>
      <slot></slot>
    </div>
    <img :src="imageUrl" :alt="title" class="slide-image">
  </div>
</template>

<script>
export default {
  name: 'slide',
  props: {
    title: { type: String, required: true },
    imageFilename: { type: String, required: true }
  },
  computed: {
    imageUrl() {
      return require(`../../../assets/img/slides/${this.imageFilename}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.slide {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 65px;

  .slide-content {
    margin-top: 80px;
    width: 29.5%;
    font-size: 1rem;
    line-height: 1.5rem;

    h2 {
      margin-bottom: 1.25rem;
      color: $brand-purple;
    }

    .brand-btn {
      margin-top: 1.5625rem;
    }
  }

  // Need to use this mixin to be able to override the scss
  // compiler that does not allow mixing PX and VW values.
  @mixin marginTop($offset, $vw, $min) {
    margin-top: #{'max(#{$offset} - #{$vw}, #{$min})'};
  }

  img {
    @include marginTop(144px, 10vw, 0px);
  }

  @include media-lg {
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-top: 0;

    .slide-content {
      margin-top: 10px;
      width: 100%!important;

      h2 {
        font-size: 1.5rem;
      }

      .brand-btn {
        margin: 10px auto;
      }
    }

    img {
      margin: 0 auto !important;
      max-width: 100%;
      width: auto!important;
      height: auto!important;
      max-height: 300px;
    }
  }
}
</style>
