'use strict';

module.exports = {
  USD: `
    The Chartered Institute of Management Accountants (CIMA) is the payment
    collection agent for the Association of International Certified Professional
    Accountants (the Association) for CGMA Finance Leadership Program sales.`,
  GBP: `
    The Chartered Institute of Management Accountants (CIMA) affiliated
    purchases of the CGMA Finance Leadership Program are sold by CIMA as principal.`
};
