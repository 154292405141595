import countries from 'config/shared/countries.json';
import differenceBy from 'lodash/differenceBy';
import { isSupported } from 'common/api/countryAffiliations';
import sortBy from 'lodash/sortBy';

const EMBARGOED_COUNTRIES = [
  { code: 'CU', name: 'Cuba' },
  { code: 'IR', name: 'Iran' },
  { code: 'KP', name: 'North Korea' },
  { code: 'SD', name: 'Sudan' },
  { code: 'SY', name: 'Syria' }
];

class Countries {
  constructor() {
    this.lookup = countries;
    this.callCodes = {};

    Object
      .entries(countries)
      .forEach(([code, { callCodes }]) => {
        callCodes.forEach(callCode => {
          this.callCodes[callCode] = { code, callCode };
        });
      });

    this.list = sortBy(this.lookup, ['name']);
    this.nonEmbargoedList = differenceBy(this.list, EMBARGOED_COUNTRIES, 'code');
  }

  getDefaultCallCode(countryCode) {
    return this.lookup[countryCode].callCodes[0];
  }

  getCountryCode(callCode) {
    return this.callCodes[callCode].code;
  }

  isSupported(code) {
    return isSupported(code)
      .then(({ isSupported }) => isSupported);
  }

  getFlag(countryCode) {
    return `https://flagcdn.com/16x12/${countryCode.toLowerCase()}.png`;
  }
}

export default new Countries();
