<template>
  <div class="section-wrapper">
    <div class="section">
      <h2>What finance leaders are saying...</h2>
      <div class="testimonials">
        <testimonial
          v-for="testimonial in testimonials"
          :key="testimonial.name"
          v-bind="testimonial" />
      </div>
    </div>
  </div>
</template>

<script>
import Testimonial from './Testimonial';

export default {
  name: 'fellow-leaders',
  data() {
    return {
      testimonials: [{
        imageFilename: 'lucas_prillaman.jpg',
        name: 'Lucas Prillaman',
        position: 'Senior Tax Associate',
        company: 'PwC',
        message: `"After being a part of the CGMA Finance Leadership
          Program, I feel even more prepared for future roles in management."`
      }, {
        imageFilename: 'hank_windhorst.jpg',
        name: 'Hank Windhorst',
        position: 'Financial Planning Consultant',
        company: 'Chick-fil-a Corporate',
        message: `"I chose to pursue the opportunity as the logical next step
          toward my goal of becoming a more well-rounded finance professional."`
      }, {
        imageFilename: 'nora_merriman.jpg',
        name: 'Nora Merriman',
        position: 'Financial Analyst',
        company: 'Unum',
        message: `"Thanks to the CGMA Finance Leadership Program, I have a greater
        understanding of financial concepts as well as general business knowledge.
        I can contribute to conversations that I used to only observe."`
      }]
    };
  },
  components: {
    Testimonial
  }
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  .section {
    text-align: center;

    .testimonials {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 40px auto;
    }
  }

  @include media-lg {
    .section {
      .testimonials {
        flex-direction: column;
      }
    }
  }
}
</style>
