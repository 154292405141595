<template>
  <div id="page-header" class="section-wrapper">
    <div class="section">
      <div class="content">
        <h1 class="title">CGMA Finance Leadership Program</h1>
        <h2 class="subtitle">Be the future of finance.</h2>
        <button @click="showModal = true" class="brand-btn btn-prominent btn-orange btn-rounded">
          Watch video
        </button>
      </div>
    </div>
    <modal v-if="showModal" @close="showModal = false" class="video-modal">
      <brightcove-video v-bind="video" />
    </modal>
  </div>
</template>

<script>
import BrightcoveVideo from './BrightcoveVideo';
import Modal from 'student/components/common/Modal';

export default {
  name: 'page-header',
  data() {
    return {
      showModal: false,
      video: {
        accountId: '1485859309',
        playerId: 'PpiswTEyK',
        videoId: '6198372196001'
      }
    };
  },
  components: {
    BrightcoveVideo,
    Modal
  }
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  height: 27rem;
  background: linear-gradient(90deg, #C54E0F 0%, #D06617 68.68%, #DF8320 100%);

  .content {
    padding-top: 7.625rem;
    text-align: center;
    color: white;

    .title {
      margin-bottom: 20px;
      font-size: 3.0625rem !important;
      line-height: 3.0625rem;
      font-weight: 100;
    }

    .subtitle {
      margin: 0;
      margin-bottom: 40px;
      font-size: 1.875rem;
      font-weight: 400;
      color: white;
    }

    .brand-btn {
      margin: 0 auto;
      border: 1px solid rgba(255,255,255,0.55);
    }

    @include media-sm {
      padding-top: 6rem;

      .title {
        margin-bottom: 10px;
        font-size: 2.5rem !important;
        line-height: 2.5rem;
      }

      .subtitle {
        font-size: 1.3125rem;
      }

      .brand-btn {
        width: 100%;
      }
    }
  }

  @include media-sm {
    .video-modal {
      ::v-deep .modal-container {
        width: 100%;
        padding: 0;
      }
    }
  }
}
</style>
