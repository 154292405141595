import Home from '../components/Home';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '*',
    redirect: { name: 'Home' }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 })
});

router.beforeEach((to, _from, next) => {
  setPageTitle(to);
  return next();
});

function setPageTitle({ matched }) {
  const nearestWithTitle = matched
    .slice()
    .reverse()
    .find(route => route.meta && route.meta.title);

  if (nearestWithTitle) {
    document.title = `${document.title} | ${nearestWithTitle.meta.title}`;
  }
}

export default router;
