import axios from 'axios';
import JWT_TOKEN from 'common/utils/jwtToken';
import { StatusCodes } from 'http-status-codes';

const BASE_URL = '/api/v1/';

// Instance of axios to be used for all API requests.
const client = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  timeout: 2 * 60 * 1000, // 2 minutes
  headers: { 'Content-Type': 'application/json' }
});

client.interceptors.request.use(config => {
  const token = window.localStorage.getItem(JWT_TOKEN);

  if (token) {
    config.headers.Authorization = `JWT ${token}`;
  } else if (!token && config.headers.Authorization) {
    delete config.headers.Authorization;
  }
  return config;
});

client.interceptors.response.use(res => res, err => {
  const { status } = err.response;

  if (status === StatusCodes.UNAUTHORIZED) {
    window.localStorage.removeItem(JWT_TOKEN);
    window.location.reload();
  } else {
    throw err;
  }
});

export default client;
