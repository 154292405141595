<template>
  <div>
    <div class="overview section-wrapper">
      <div class="background"></div>
      <div class="section">
        <img src="../../../assets/img/Transparent_shape_overlay.png" class="swoosh" alt="Title overlay">
        <h2>Accelerate your finance career</h2>
      </div>
      <div class="program-overview">
        <div class="content">
          The CGMA® Finance Leadership Program is a competency-based digital learning experience
          and your pathway to earn The Chartered Institute of Management Accountants (CIMA)®
          Professional Qualification and the Chartered Global Management Accountant (CGMA)
          designation — the most relevant global finance qualification for a career in business,
          held by 150,000 finance professionals worldwide.
        </div>
      </div>
    </div>
    <icon-section />
  </div>
</template>

<script>
import IconSection from './IconSection';

export default {
  name: 'program-overview',
  components: {
    IconSection
  }
};
</script>

<style lang="scss" scoped>
.overview.section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 736px;
  overflow: hidden;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 800px;
    background-image: url('../../../assets/img/AICPA_still_iStock-907768328_flipped.jpg');
    background-repeat: no-repeat;
    background-position: 50% 45%;
    background-size: cover;
  }

  .section {
    position: relative;
    max-width: 1100px;
    width: 100%;
    z-index: 2;

    .swoosh {
      position: absolute;
      width: 2252px;
      height: 360px;
      top: -240px;
      left: -945px;
      opacity: 0.86;
      z-index: 1;
    }

    h2 {
      position: relative;
      z-index: 10;
      margin-top: 20px;
      padding-left: 20px;
      color: $darker-gray;
    }
  }

  .program-overview {
    display: flex;
    min-height: 231px;
    position: relative;
    z-index: 2;
    padding: 3.125rem 0;
    background: linear-gradient(180deg, rgba(255,255,255,0.87) 0%, #FFFFFF 100%);

    .content {
      margin: auto;
      width: 900px;
      text-align: center;
      font-size: 1.125rem;
      line-height: 2rem;
      color: $text-color;
    }
  }

  @include media-xlg {
      height: 636px;

      .background {
        height: 600px;
      }
  }

  @include media-lg {
    .background {
      top: -30px;
      background-position: bottom;
    }

    .section {
      .swoosh {
        width: 1900px;
        height: 260px;
        top: -140px;
        left: -890px;
      }
    }
  }

  @include media-md {
    height: 566px;

    .program-overview {
      top: 30px;
      height: auto;
    }

    .background {
      height: 500px;
    }
  }

  @include media-sm {
    height: auto;

    .background {
      top: 0px;
      height: 230px;
    }

    .section {
      width: 100%;
      height: 200px;
      padding: 15px;

      h2 {
        margin: 0 auto 0 0;
        width: 240px;
        font-size: 1.25rem;
        line-height: 1.875rem;
      }

      .swoosh {
        width: 1000px;
        height: 150px;
        top: -65px;
        left: -450px;
        opacity: .95;
      }
    }

    .program-overview {
      top: -40px;
      height: auto;
      padding: 30px 0;
      background: linear-gradient(180deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.98) 20%, #FFFFFF 100%);

      .content {
        width: 100%;
      }
    }
  }
}
</style>
