<template>
  <accordion title="Management">
    <ul class="accordion-content">
      <li class="subtext">
        At the Management Level, you’ll develop core skills and business acumen while acquiring
        more advanced tools and frameworks.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_certificate_small.png" class="icon" alt="Certificate icon">
        Team Performance Management Skills Certificate
      </li>
      <li class="content-item">
        <span class="subtitle">
          Business Models
        </span>
        Recognize the dynamics of organizational ecosystems and business model frameworks.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Leading and Managing People
        </span>
        Apply management and leadership approaches that improve team performance.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Project Management
        </span>
        Apply project management tools and techniques that drive change.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_certificate_small.png" class="icon" alt="Certificate icon">
        Financial Planning and Analysis Skills Certificate
      </li>
      <li class="content-item">
        <span class="subtitle">
          Cost Competitiveness
        </span>
        Advise on techniques to manage costs and achieve sustainable competitive advantage.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Organizational Performance
        </span>
        Manage performance of organization units.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Pricing and Capital Investment Decisions
        </span>
        Support pricing strategies and capital investment decision making.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Decision Making and Risk
        </span>
        Identify and manage risk associated with decision making.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_certificate_small.png" class="icon" alt="Certificate icon">
        Financial Statement Analysis Skills Certificate
      </li>
      <li class="content-item">
        <span class="subtitle">
          Advanced Financial Statements
        </span>
        Apply recognition, measurement and presentation criteria used in International Financial
        Reporting Standards.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Long-Term Financing
        </span>
        Advise on sources of long-term finance and associated costs.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Complex Group Reporting
        </span>
        Prepare financial statements according to relevant international accounting standards.
      </li>
      <li class="content-item">
        <span class="subtitle">
          Financial Statement Analysis
        </span>
        Provide insights about financial performance and position.
      </li>
      <li class="header">
        <img src="../../../../assets/img/AICPA_icon_accordionexpand_diploma_small.png" class="icon" alt="Diploma icon">
        Advanced Diploma in Management Accounting
      </li>
      <li class="content-item">
        Receive your diploma after completing the Management Learning Level and the Management Case Study.
      </li>
    </ul>
  </accordion>
</template>

<script>
import Accordion from 'student/components/common/Accordion';

export default {
  name: 'management',
  components: {
    Accordion
  }
};
</script>
