<template>
  <div v-click-outside="close" class="view-more-container">
    <div class="pointer"></div>
    <div class="close-section">
      <v-btn @click="close" icon>
        <font-awesome-icon :icon="['fal', 'times']" class="close-icon" />
      </v-btn>
    </div>
    <h4 class="mb-4">
      The additional features of Skills Premium are provided by our official
      partner Kaplan. They include:
    </h4>
    <p class="feature-title">Live, Tutor-led Sessions</p>
    <div>
      Attend live, online sessions for competency learning and case study preparation
      classes totaling around 30 tutor-led class hours per class (180 live sessions
      across all 3 levels). All live sessions are recorded and can be viewed on
      the student platform at your convenience.
      <a
        href="https://kaplan.co.uk/docs/default-source/pdfs/timetables/cima/cima-flp-timetable.pdf?sfvrsn=d4121c01_14"
        target="_blank"
        class="class-schedules-link">
        See class schedules
      </a>
    </div>
    <p class="feature-title">Enhanced Case Study Preparation</p>
    <div>
      Live tuition sessions for all stages of case study preparation and access
      to additional mocks & materials.
    </div>
    <p class="feature-title">Expert tutor support</p>
    <div>
      Available if you need additional help or support with your studies. You can
      contact your tutors 7 days a week, 362 days a year via live chat or email.
    </div>
    <p class="feature-title">Personal Coach</p>
    <div>
      Personal coaches offer advice, guidance, and support on how to best learn
      and progress through your course. They monitor your progress and will help
      you get back on track should you need it.
    </div>
    <p class="feature-title">Premium Study Materials</p>
    <div>
      Access to workbooks that support your progression and exam success. This
      includes a wealth of additional material based around real case study
      pre-seen to ensure you have the best chance of success.
    </div>
    <p class="feature-title">Structure & Study Planning</p>
    <div>
      Live, online sessions; workbooks; and study planning enable you to fast-track
      your studies whilst improving your chances for successful case study exam
      results without losing the flexibility our program offers.
    </div>
    <div class="d-flex justify-center mt-3">
      <img src="../../../../assets/img/kaplan_logo.png" class="kaplan-logo">
    </div>
  </div>
</template>

<script>
export default {
  name: 'view-more-popup',
  data() {
    return {
      initialized: false
    };
  },
  methods: {
    close() {
      if (!this.initialized) return;
      return this.$emit('close');
    }
  },
  mounted() {
    this.initialized = true;
  }
};
</script>

<style lang="scss" scoped>
.view-more-container {
  position: absolute;
  top: 8.75rem;
  right: 2rem;
  width: 40rem;
  padding: 1.25rem 1.25rem 1.875rem;
  text-align: left;
  background-color: #FFF;
  border: 1px solid $brand-purple;
  z-index: 100;

  .pointer {
    position: absolute;
    top: -1rem;
    right: 5.625rem;
    height: 1.875rem;
    width: 1.875rem;
    background-color: #FFF;
    border: 1px solid $brand-purple;
    transform: rotate(45deg);
    border-right: unset;
    border-bottom: unset;
  }
}

.close-section {
  display: none;
  justify-content: flex-end;
  margin-bottom: 0.875rem;

  .close-icon {
    font-size: 2rem;
    color: $brand-purple;
  }
}

.feature-title {
  color: $brand-purple;
  font-size: 0.9375rem;
  font-weight: 500;
  margin: 1.25rem 0 0.375rem;
}

.class-schedules-link {
  color: $brand-purple;
  font-weight: 500;
}

.kaplan-logo {
  width: 11rem;
}

  @include media-md {
    .view-more-container {
      position: fixed;
      top: 0;
      left:0;
      width: 100% !important;
      min-height: 100% !important;
      overflow-y: scroll;
      border: unset;

      .close-section {
        display: flex;
      }

      .pointer {
        display: none;
      }
    }
  }
</style>
