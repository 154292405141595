<template>
  <div id="international" class="section-wrapper">
    <div class="section">
      <div class="organization">
        <div class="title">
          <img class="logo" src="../../assets/img/cima_logo.png" alt="CIMA logo">
          <h2>Enter the world's largest body of management accountants</h2>
          <div class="subtitle">For finance professionals in Asia, Africa, Europe and Australia:</div>
        </div>
        <div class="content">
          <p>
            When you earn your CGMA designation, you also earn CIMA Membership as either an ACMA or FCMA.
            CIMA Membership lets clients and colleagues know you’re part of a respected community of
            exemplary professionals in your field. Earning CIMA Membership is the gateway to joining a
            community of peers committed to lifelong learning, ethical
          </p>
          <p>
            conduct and the highest standards of professionalism. The biggest names in the FTSE 100 seek
            out CIMA members. Members receive access to CIMA’s curated thought leadership content, webinar
            library, discounted learning solutions, confidential support line and other exclusive resources
            and benefits.
          </p>
        </div>
        <div class="learn-more-section">
          <a target="_blank" href="https://www.cimaglobal.com/" class="brand-btn btn-prominent">Learn more</a>
        </div>
      </div>
      <div class="organization">
        <div class="title">
          <img class="logo" src="../../assets/img/aicpa_logo.png" alt="AICPA logo">
          <h2>Join the world's largest accounting association</h2>
          <div class="subtitle">For finance professionals in North America and South America:</div>
        </div>
        <div class="content">
          <p>
            When you begin the Finance Leadership Program to pursue a CGMA designation, you’ll also become
            a member of AICPA.<br>
            With over 400,000 members, AICPA represents the world’s largest membership body of accounting
            professionals. While earning the CGMA designation, you can take advantage of AICPA benefits,
            access exclusive
          </p>
          <p>
            resources and interact with a community that drives the profession. Among other advantages,
            AICPA provides its members with professional development opportunities, business discounts,
            news and publications and community enrichment programs as well as legislative advocacy.
          </p>
        </div>
        <div class="learn-more-section">
          <a target="_blank" href="https://www.aicpa.org/" class="brand-btn btn-prominent">Learn more</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'international'
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  background: linear-gradient(90deg, #00857D 0%, #158E6B 100%);

  .section {
    margin: 0 auto;
    padding-left: 155px;
    color: white;

    .organization {
      margin: 50px 0;

      .title {
        .logo {
          margin: 0 1.25rem 1.875rem 0;
          width: 150px;
        }

        h2 {
          margin-bottom: 1.25rem;
          color: white;
        }

        .subtitle {
          margin-bottom: 0.9375rem;
          font-size: 1.375rem;
          font-weight: 500;
        }
      }

      .content {
        display: flex;

        p {
          width: 550px;
          font-size: 1.0625rem;
          line-height: 2rem;

          + p {
            margin-left: 40px;
          }
        }
      }
    }
  }

  @include media-lg {
    .section {
      padding: 10px 20px;
    }

    .learn-more-section {
      display: flex;
      justify-content: center;
    }
  }

  @include media-sm {
    .section {
      .organization {
        .content {
          flex-direction: column;

          p {
            width: 100%;

            + p {
              margin: 0;
            }
          }
        }
      }

      .brand-btn {
        margin-top: 20px;
      }
    }
  }
}
</style>
