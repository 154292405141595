import { alpha, email, length, max, numeric, required } from 'vee-validate/dist/rules';
import { extend, setInteractionMode, ValidationProvider } from 'vee-validate';
import countries from 'common/utils/countries';

setInteractionMode('custom', ({ errors }) => {
  const on = ['input', 'change'];
  const debounce = errors.length ? 0 : 700;
  return { on, debounce };
});

// EXTENDED_ALPHA_CHAR is a subset of the supported [\x00-\xFF] range but
// contains alphabetic characters from the range, excluding special ones like a
// dot, space, etc.

const charRange = 'a-zA-ZàáâäãåèéêëìíîïñòóôöõøùúûüÿýçÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜÝÑßÇÆð';

const STARTS_WITH_EXTENDED_ALPHA_CHAR = new RegExp(`^[${charRange}]`);

const ALLOWED_CHAR_REGEX = /^[\x00-\xFF]*$/;
const EXTENDED_ALPHA_DASH_REGEX = new RegExp(`^[${charRange}-\\s]*$`);
const NAME_CHAR_REGEX = new RegExp(`^[${charRange}-\\s.']*$`);
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

const ENDS_WITH_EXTENDED_ALPHA_CHAR = new RegExp(`[${charRange}]$`);
const ENDS_WITH_EXTENDED_ALPHA_CHAR_OR_DOT = new RegExp(`[${charRange}.]$`);

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('email', {
  ...email,
  message: 'This is not a valid email address'
});

extend('alpha', {
  ...alpha,
  message: 'This field can contain only alphabetic characters'
});

extend('alphaDash', {
  validate: value => {
    if (!STARTS_WITH_EXTENDED_ALPHA_CHAR.test(value)) {
      return 'This field has to start with an alphabetic character';
    } else if (!ENDS_WITH_EXTENDED_ALPHA_CHAR.test(value)) {
      return 'This field has to end with an alphabet character';
    } else if (!EXTENDED_ALPHA_DASH_REGEX.test(value)) {
      return 'This field can contain only alphabetic characters, spaces and dashes';
    }
    return true;
  }
});

extend('name', {
  validate: value => {
    if (!STARTS_WITH_EXTENDED_ALPHA_CHAR.test(value)) {
      return 'This field has to start with an alphabetic character';
    } else if (!ENDS_WITH_EXTENDED_ALPHA_CHAR_OR_DOT.test(value)) {
      return 'This field has to end with an alphabet character or a dot';
    } else if (!NAME_CHAR_REGEX.test(value)) {
      return 'This field can contain only alphabetic characters, spaces, dashes and apostrophes';
    }
    return true;
  }
});

extend('numeric', {
  ...numeric,
  message: 'This field can only contain numbers'
});

extend('password', {
  params: ['target'],
  validate: (value, { target }) => {
    if (target && value !== target) {
      return 'The passwords do not match';
    }
    if (!PASSWORD_REGEX.test(value)) {
      return 'The password does not meet the password requirements';
    }
    return true;
  }
});

extend('supportedCharset', {
  validate: value => {
    if (ALLOWED_CHAR_REGEX.test(value)) return true;
    return 'Special characters are not allowed';
  }
});

extend('max', {
  ...max,
  message: 'Number of characters cannot be more than {length}'
});

extend('digitLength', {
  ...length,
  message: 'Number of digits must be {length}'
});

extend('supportedCountry', {
  params: ['voucher'],
  validate: async (value, { voucher }) => {
    if (!value || voucher === 'true') return true;
    return await countries.isSupported(value.code);
  },
  message: 'Location not supported without voucher'
});

extend('cleanVoucherInput', {
  params: ['id', 'entered'],
  validate: async (value, { id, entered }) => entered !== 'true' || id === value
});

extend('university', {
  validate: value => {
    if (!value.custom) return true;
    if (ALLOWED_CHAR_REGEX.test(value.institutionName)) return true;
    return 'Special characters are not allowed';
  }
});

export default ValidationProvider;
