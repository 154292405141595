<template>
  <ul v-once class="features">
    <li v-for="(feature, index) in parsedFeatures" :key="index">
      <span class="label">{{ feature.label }}</span>
      <font-awesome-icon
        :icon="feature.included ? ['fal', 'check'] : ['fal', 'minus']"
        :class="{ minus: !feature.included }"
        class="icon" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'package-features',
  props: {
    features: { type: Object, required: true }
  },
  computed: {
    parsedFeatures() {
      const { features } = this;
      return [
        { label: 'Competencies', included: !!features.competencies },
        { label: 'Business Simulations', included: !!features.bs },
        { label: 'Skills Certificates', included: !!features.skillsCertificate },
        { label: 'Registration & Student Fees', included: !!features.studentFee },
        { label: 'Case Study Exam Review Course', included: !!features.cseReview },
        { label: 'Case Study Exam Fees', included: !!features.cseFee },
        { label: 'Diplomas & Designation', included: !!features.diplomas },
        { label: 'Expert Tutor Support', included: !!features.tutorSupport },
        { label: 'Live Sessions', included: !!features.liveSessions },
        { label: 'Student Coaching', included: !!features.coaching }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.features {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid $lighter-gray;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid $lighter-gray;

    &:nth-child(odd) {
      background: $lightest-gray;
    }

    .label {
      display: none;
    }

    .icon {
      width: 20px;
      font-size: 1.4375rem;
      color: $brand-purple;

      &.minus {
        font-size: 0.75rem;
        color: $light-gray;
      }
    }
  }
}

@include media-xlg {
  .features {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;

      .label {
        display: inline-block;
        font-size: 0.875rem;
        text-align: left;
      }
    }
  }
}
</style>
