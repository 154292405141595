<template>
  <div class="item accelerate-growth">
    <img src="../../../../assets/img/AICPA__Icon_growthChart.png" alt="Accelerating bar chart">
    <div class="title">Accelerate career growth</div>
    <p class="content">
      Become a Strategic Leader — Develop the competencies that accelerate career
      growth by evolving beyond technical accounting to take on strategic and
      management priorities.
    </p>
  </div>
</template>

<script>
export default {
  name: 'accelerate-growth'
};
</script>
