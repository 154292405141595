<template>
  <div id="CSE" class="section-wrapper">
    <div class="section">
      <h2>The Case Study Exams</h2>
      <div class="subheader">
        Focus on case study exams
      </div>
      <div class="items">
        <div class="item">
          <img src="../../assets/img/AICPA__Icon_CaseStudyReviewCourse.png" alt="Case study review course icon">
          <div class="content">
            The Finance Leadership Program’s <span class="bold">Case Study Review Course</span>
            prepares you for the actual case study exams. Students analyse a scenario involving
            a model business through self-paced video lectures, assignments and practice tests.
            Pre-seen materials provided before the exam, including information about the model
            business, frame an immersive real world experience.
          </div>
        </div>
        <div class="item">
          <img src="../../assets/img/AICPA__Icon_CaseStudyExam.png" alt="Case study exam icon">
          <div class="content">
            <span class="bold">Case study exams</span> offer a simulation-based testing format
            that brings together the knowledge, skills and techniques you acquire throughout
            the CGMA Finance Leadership Program and across each of the three levels: Operational,
            Management and Strategic. You’ll conduct research and execute solutions to complex
            challenges facing a model company in a comprehensive real world scenario.
          </div>
        </div>
      </div>
      <div class="pass-rates">
        After extensive analysis during 2022 / 2023, The CGMA FLP pathway Pass
        rates are consistent with other Pathway outcomes, and we are seeing a
        higher pass rate with FLP Premium students across all levels (between
        5-15% higher during 2023). Published pass rates are available at
        <a
          target="_blank"
          href="https://www.aicpa-cima.com/resources/landing/exam-pass-rates">
          Exam pass rates.
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'case-study'
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  background: linear-gradient(36.52deg, #C54E0F 0%, #D06617 68.68%, #DF8320 100%);

  .section {
    padding: 3.5625rem 1.25rem;
    text-align: center;
    color: white;

    h2 {
      margin-bottom: 1.25rem;
      color: white;
    }

    .subheader {
      font-size: 1.25rem;
      font-weight: 500;
    }

    .items {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 40px auto;
      width: 1130px;

      .item {
        width: 540px;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 2rem;

        img {
          height: 70px;
        }
      }
    }

    .pass-rates {
      margin: 40px auto 0;
      width: 1130px;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 2rem;

      a {
        color: #FFFFFF;
        font-weight: 500;
      }
    }
  }

  @include media-lg {
    .section {
      h2 {
        font-size: 1.75rem;
      }

      .subheader {
        font-size: 1.1875rem;
      }

      .items {
        margin-top: 0;
        width: 100%;

        .item {
          margin: 50px auto 0;
          width: 360px;

          + .item {
            margin-left: 20px;
          }
        }
      }

      .pass-rates {
        margin-top: 0px;
        width: 100%;
      }
    }
  }

  @include media-sm {
    .section {
      .items {
        flex-wrap: wrap;
        align-items: center;
        margin-top: 0;
        width: 100%;

        .item {
          margin: 50px auto 0;
          width: 100%;

          + .item {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
