<template>
  <div id="howItWorks" class="section-wrapper">
    <div class="section">
      <slider :slide-count="9">
        <slide
          title="Personalize your learning journey"
          image-filename="personalize_learning_journey.png"
          class="personalize-learning">
          <p>
            Three overarching <span class="bold">Levels</span> make up the
            <span class="bold">Finance Leadership Program</span>: Operational, Management and
            Strategic.
          </p>
          <p>
            Begin at the right Level based on your qualifications and education.
          </p>
          <p>
            With a learning experience that fits your busy schedule, study online and on any device.
          </p>
          <button @click="showModal = true" class="brand-btn btn-prominent btn-light-purple">Watch video</button>
        </slide>
        <slide
          title="Accelerate career growth"
          image-filename="accelerate_career_growth.png"
          class="accelerate-growth">
          <p>
            All three Levels are comprised of <span class="bold">Competencies</span> that build off
            each other to reinforce skills.
          </p>
          <p>
            Complete each Competency to get actionable frameworks that accelerate career growth
            and bring you closer to the CGMA Designation.
          </p>
        </slide>
        <slide
          title="Develop in-demand skills"
          image-filename="develop_indemand_skills.png"
          class="develop-skills">
          <p>
            Each <span class="bold">Competency</span> is composed of critical, actionable skills
            identified by finance executives from over 2,000 companies around the world.
          </p>
          <p>
            Focus on the skills that matter and build one skill at a time.
          </p>
        </slide>
        <slide
          title="Demonstrate expert knowledge"
          image-filename="demonstrate_expert_knowledge.png"
          class="demonstrate-knowledge">
          <p>
            Learning and assessment are seamlessly blended to ensure you understand key topics
            and can apply new skills.
          </p>
          <p>
            <span class="bold">Pre-assessments</span> evaluate your prior experience so you can
            concentrate on learning new skills.
          </p>
        </slide>
        <slide
          title="Apply your new skills in practice"
          image-filename="expert_knowledge_bsa.png"
          class="apply-skills">
          <p>
            <span class="bold">Business Simulations</span> across each level assess comprehension,
            with assignments in a case-based format that builds confidence in solving business
            problems and applying new skills.
          </p>
        </slide>
        <slide
          title="Earn skills certificates and diplomas"
          image-filename="competency_certificate_diploma.png"
          class="earn-certificates">
          <p>
            Earn <span class="bold">Skills Certificates</span> by completing Competencies within
            each Level.
          </p>
          <p>
            Receive <span class="bold">Diplomas</span> by passing the case study exams at the
            Operational and Management Levels.
          </p>
          <p>
            <span class="bold">Skills Certificates</span> and <span class="bold">Diplomas</span>
            showcase the knowledge acquired throughout the Finance Leadership Program.
          </p>
        </slide>
        <slide
          title="Prepare to earn your designation"
          image-filename="earn_designation.png"
          class="prepare-designation">
          <p>
            <span class="bold">CGMA Case study exams</span> use a simulation-based testing format
            to assess the Competencies developed throughout each Level.
          </p>
          <p>
            <span class="bold">Case Study Review Courses</span> prepare you for the exam. Analysis
            of the fictitious business featured in the exam, combined with practice exams and
            personalized feedback from experts, ensures you’re ready to pass the real thing.
          </p>
        </slide>
        <slide
          title="Learn & Progress with Live Classes"
          image-filename="live_classes.png"
          class="live-classes">
          <p>
            If you are a Skills Premium subscriber, you can enroll in a class for competency
            learning and case study preparation in your level and attend live, online sessions
            that include around 30 tutor-led hours per class.
          </p>
          <p>
            All live, online sessions are recorded and can be viewed at your own convenience
            on the student platform.
          </p>
          <p>
            Live, online sessions are delivered in partnership with Kaplan.
          </p>
        </slide>
        <slide
          title="Help if you need it with Tutor support and coaching"
          image-filename="contact_tutor.png"
          class="contact-tutor">
          <p>
            Support from Kaplan experts is available to Skills Plus & Premium students
            (some exemptions apply). Students can contact your tutors 7 days a week
            via live chat or email.
          </p>
          <p>
            In addition, Skills Premium students coaches offer advice and guidance on
            how to best progress through your learning.
          </p>
        </slide>
      </slider>
    </div>
    <modal v-if="showModal" @close="showModal = false" class="video-modal">
      <brightcove-video v-bind="video" />
    </modal>
  </div>
</template>

<script>
import BrightcoveVideo from '../BrightcoveVideo';
import Modal from '../../common/Modal';
import Slide from './Slide';
import Slider from '../../common/Slider';

export default {
  name: 'personalize-learning',
  data() {
    return {
      showModal: false,
      video: {
        accountId: '1485859309',
        playerId: 'PpiswTEyK',
        videoId: '6198370069001'
      }
    };
  },
  components: {
    BrightcoveVideo,
    Modal,
    Slide,
    Slider
  }
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  background-color: $lightest-gray;

  .personalize-learning {
    ::v-deep .slide-content {
      width: 29.5%;
    }

    ::v-deep .slide-image {
      margin-left: -4%;
      margin-right: -8%;
      width: 74%;
    }
  }

  .accelerate-growth {
    ::v-deep .slide-image {
      margin-left: -5%;
      margin-right: -8%;
      width: 74%;
    }
  }

  .develop-skills {
    ::v-deep .slide-image {
      margin-left: 4%;
      width: 54%;
    }
  }

  .demonstrate-knowledge {
    ::v-deep .slide-image {
      margin-left: 9%;
      width: 45%;
    }
  }

  .apply-skills,
  .earn-certificates {
    ::v-deep .slide-image {
      margin-left: -4%;
      margin-right: -8%;
      width: 74%;
    }
  }

  .prepare-designation {
    ::v-deep .slide-image {
      margin-left: 3.5%;
      width: 54%;
    }
  }

  .live-classes {
    ::v-deep .slide-image {
      margin-left: -4%;
      width: 74%;
    }
  }

  .contact-tutor {
    ::v-deep .slide-image {
      margin-left: 9%;
      width: 45%;
    }
  }

  ::v-deep .navigation {
    margin-bottom: 20px;
  }

  @include media-sm {
    .video-modal {
      ::v-deep .modal-container {
        width: 100%;
        padding: 0;
      }
    }
  }
}
</style>
