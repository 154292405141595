'use strict';

const CPA_FEATURES = [
  'Two case study exam credits, good for one year',
  'Access to CGMA Case Study Review Course for one year',
  'Access to all learning content for one year'
];

module.exports = [{
  name: 'Full Program',
  title: 'Full Finance Leadership Program',
  value: 'CGMA Program - CPA Pathway Entry - Full Program - 1-year Access',
  typeRegex: /Full Program/i,
  features: [
    { label: CPA_FEATURES[0], checked: true },
    { label: CPA_FEATURES[1], checked: true },
    { label: CPA_FEATURES[2], checked: true }
  ],
  prices: { USD: 1995, GBP: 315 },
  subtext: `Recommended for members without extensive or recent experience
  in a management accounting role, as the skills required to pass the
  Strategic Case Study exam can be greatly distinct from those used regularly
  in public accounting.`
}, {
  name: 'Exam & Case Study Review',
  title: 'Exam & Case Study Review Course',
  value: 'CGMA Program - CPA Pathway Entry - Exam + Prep only - 1-year Access',
  typeRegex: /Exam \+ Prep only/i,
  features: [
    { label: CPA_FEATURES[0], checked: true },
    { label: CPA_FEATURES[1], checked: true },
    { label: CPA_FEATURES[2] }
  ],
  prices: { USD: 795, GBP: 315 },
  subtext: `Recommended for members with significant management accounting
  experience, and provides robust preparation for the case study exam.`
}, {
  name: 'Exam Only',
  title: 'Exam Only Course',
  value: 'CGMA Program - CPA Pathway Entry - Exam only',
  typeRegex: /Exam only/i,
  features: [
    { label: CPA_FEATURES[0], checked: true },
    { label: CPA_FEATURES[1] },
    { label: CPA_FEATURES[2] }
  ],
  prices: { USD: 495, GBP: 0 },
  subtext: `Only recommended for members with extensive management accounting
  experience who have taken similar case study exams before.`
}];
