<template>
  <div class="section-wrapper">
    <div class="section">
      <h2>Curriculum</h2>
      <foundational class="accordion-item" />
      <operational class="accordion-item" />
      <management class="accordion-item" />
      <strategic class="accordion-item" />
    </div>
  </div>
</template>

<script>
import Foundational from './accordion-items/Foundational';
import Management from './accordion-items/Management';
import Operational from './accordion-items/Operational';
import Strategic from './accordion-items/Strategic';

export default {
  name: 'curriculum',
  components: {
    Foundational,
    Management,
    Operational,
    Strategic
  }
};
</script>

<style lang="scss" scoped>
.section {
  width: 940px;
  margin-bottom: 40px;

  .accordion-item {
    margin-top: 25px;
  }

  ::v-deep .accordion-content {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 15px 0;
      border-bottom: 1px solid $lighter-gray;
    }

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.1875rem;
      font-weight: 500;
      color: $brand-purple;

      .icon {
        margin-right: 5px;
        width: 23px;
        color: $brand-purple;
      }
    }

    .subtext {
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.75rem;
    }

    .content-item {
      padding-left: 25px;
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 300;

      .subtitle {
        box-sizing: border-box;
        margin-right: 10px;
        padding-right: 5px;
        font-weight: 400;
        border-right: 1px solid black;
      }
    }
  }

  @include media-lg {
    width: 100%;
  }
}
</style>
