<template>
  <div>
    <top-menu @goToEnrollment="goToEnrollment" />
    <page-header />
    <program-overview />
    <learning-experience />
    <slides />
    <testimonials />
    <designation @goToEnrollment="goToEnrollment" />
    <competency-certification />
    <earn-the-designation />
    <case-study-exams />
    <gateways @goToEnrollment="goToEnrollment" />
    <packages />
    <fellow-leaders />
    <international />
    <employers-universities />
    <v-footer />
  </div>
</template>

<script>
import CaseStudyExams from './CaseStudyExams';
import CompetencyCertification from './CompetencyCertification';
import Designation from './Designation';
import EarnTheDesignation from './EarnTheDesignation';
import EmployersUniversities from './EmployersUniversities';
import FellowLeaders from './FellowLeaders';
import Gateways from './Gateways';
import International from './International';
import LearningExperience from './LearningExperience';
import { mapGetters } from 'vuex';
import Packages from './Packages';
import PageHeader from './PageHeader';
import ProgramOverview from './ProgramOverview';
import Slides from './Slides';
import Testimonials from './Testimonials';
import TopMenu from 'student/components/common/TopMenu';
import VFooter from 'student/components/common/Footer';

export default {
  name: 'home',
  computed: mapGetters('user', ['user']),
  methods: {
    goToEnrollment() {
      window.open(process.env.VUE_APP_RAVE_ENROLL_URL, '_blank');
    }
  },
  components: {
    CaseStudyExams,
    CompetencyCertification,
    Designation,
    EarnTheDesignation,
    EmployersUniversities,
    FellowLeaders,
    Gateways,
    International,
    LearningExperience,
    Packages,
    PageHeader,
    ProgramOverview,
    Slides,
    Testimonials,
    TopMenu,
    VFooter
  }
};
</script>
