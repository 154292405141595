'use strict';

module.exports = {
  SKILLS_PACKAGE: {
    title: 'Skills Core',
    available: false,
    bestValue: false,
    altColorScheme: true,
    isSkillsPackage: true,
    subtext: `Develop the competencies you need to to accelerate your career and
    earn Skills Certificates to showcase accomplishments.`,
    features: {
      competencies: true,
      bs: true,
      diplomas: false,
      skillsCertificate: true,
      studentFee: true,
      multiYearSubDiscount: false,
      cseReview: false,
      cseFee: false,
      liveSessions: false,
      tutorSupport: false,
      coaching: false
    },
    priceOptions: {
      default: [{
        time: '6 months',
        value: '6-month',
        voucherOnly: true,
        prices: {
          USD: 1195,
          GBP: 995
        }
      }, {
        time: '1 year',
        value: '1-year',
        prices: {
          USD: 2000,
          GBP: 1600
        }
      }],
      university: {
        basic: [{
          time: '1 year',
          value: '1-year',
          prices: {
            USD: 375,
            GBP: 300
          }
        }]
      }
    }
  },
  SKILLS_PLUS_PACKAGE: {
    title: 'Skills Plus',
    available: true,
    bestValue: false,
    isPlusPackage: true,
    subtext: `Develop career critical competencies, plus advance your journey
    toward CGMA Designation by taking case study exams.`,
    features: {
      competencies: true,
      bs: true,
      diplomas: true,
      skillsCertificate: true,
      studentFee: true,
      multiYearSubDiscount: true,
      cseReview: true,
      cseFee: true,
      liveSessions: false,
      tutorSupport: true,
      coaching: false
    },
    priceOptions: {
      default: [{
        time: '1 year',
        value: '1-year',
        prices: {
          USD: 2500,
          GBP: 2000
        }
      }, {
        time: '2 years',
        value: '2-year',
        prices: {
          USD: 4250,
          GBP: 3400
        }
      }, {
        time: '3 years',
        value: '3-year',
        prices: {
          USD: 6375,
          GBP: 5100
        }
      }],
      university: {
        basic: [{
          time: '1 year',
          value: '1-year',
          prices: {
            USD: 750,
            GBP: 600
          }
        }, {
          time: '2 years',
          value: '2-year',
          prices: {
            USD: 1275,
            GBP: 1020
          }
        }, {
          time: '3 years',
          value: '3-year',
          prices: {
            USD: 1913,
            GBP: 1530
          }
        }],
        disruption: [{
          time: '1 year',
          value: '1-year',
          prices: {
            USD: 305,
            GBP: 235
          }
        }]
      }
    }
  },
  SKILLS_PREMIUM_PACKAGE: {
    title: 'Skills Premium',
    available: true,
    bestValue: true,
    altColorScheme2: true,
    isPremiumPackage: true,
    subtext: `Develop career-critical competencies and advance toward CGMA with
    interactive live classes and support from experts.`,
    viewMorePremium: true,
    features: {
      competencies: true,
      bs: true,
      diplomas: true,
      skillsCertificate: true,
      studentFee: true,
      multiYearSubDiscount: true,
      cseReview: true,
      cseFee: true,
      liveSessions: true,
      tutorSupport: true,
      coaching: true
    },
    priceOptions: {
      default: [{
        time: '1 year',
        value: '1-year',
        prices: {
          USD: 3750,
          GBP: 3000
        }
      }, {
        time: '2 years',
        value: '2-year',
        prices: {
          USD: 6375,
          GBP: 5100
        }
      }, {
        time: '3 years',
        value: '3-year',
        prices: {
          USD: 9563,
          GBP: 7650
        }
      }],
      university: {
        basic: [{
          time: '1 year',
          value: '1-year',
          prices: {
            USD: 495,
            GBP: 495
          }
        }, {
          time: '2 years',
          value: '2-year',
          prices: {
            USD: 795,
            GBP: 795
          }
        }, {
          time: '3 years',
          value: '3-year',
          prices: {
            USD: 1195,
            GBP: 1195
          }
        }],
        disruption: [{
          time: '1 year',
          value: '1-year',
          prices: {
            USD: 305,
            GBP: 235
          }
        }]
      }
    }
  }
};
