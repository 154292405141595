<template>
  <div id="gateways" class="section-wrapper">
    <div class="section">
      <div class="header">
        <h2>
          Who is eligible?
        </h2>
        <p class="emphasized">
          Anyone is able to start the program. Also, if you already have other
          academic or professional qualifications, or relevant professional experience,
          you may be exempt from certain elements of the program. Your exact exemptions
          and entry point to the program is determined during registration based on your
          specific background. The following are examples of entry points that some of our
          Candidates receive:
        </p>
      </div>
      <div class="gateways">
        <gateway
          v-for="(gateway, index) in gateways"
          :key="index"
          v-bind="gateway" />
      </div>
      <button
        @click="$emit('goToEnrollment')"
        class="brand-btn btn-prominent btn-purple take-assessment">
        ENROLL NOW
      </button>
    </div>
  </div>
</template>

<script>
import Gateway from './Gateway';

const EDUCATION_GATEWAY_DATA = {
  iconName: 'AICPA__Icon_Education_Diploma.png',
  title: 'Education',
  content: `
    Each degree is different, but accounting and finance degrees often qualify for
    entry at the management level, and business degrees often qualify for entry at the operational level.`
};

const NEW_GATEWAY_DATA = {
  iconName: 'AICPA__Icon_Open_Door.png',
  title: 'Work Experience',
  content: 'Direct, relevant experience in true accounting or finance roles may provide exemptions as well.'
};

const PROFESSIONAL_QUALIFICATION_DATA = {
  iconName: 'AICPA__Icon_ProfQualification_Calculator.png',
  title: 'Professional Qualification',
  content: `
    Full members of IFAC-registered accounting bodies often qualify for entry at
    management level, and US CPAs qualify for entry at strategic level.`
};

export default {
  name: 'gateways',
  data() {
    return {
      gateways: [EDUCATION_GATEWAY_DATA, PROFESSIONAL_QUALIFICATION_DATA, NEW_GATEWAY_DATA]
    };
  },
  components: {
    Gateway
  }
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  background: $lightest-gray;

  .section {
    padding: 40px 0;

    .header {
      margin: auto;
      width: 1064px;
      text-align: center;

      h2 {
        margin-bottom: 1.25rem;
        line-height: 2.75rem;
      }

      .emphasized {
        margin-bottom: 3rem;
        font-weight: 400;
        color: #000;
      }
    }

    .gateways {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1064px;
      margin: auto;
    }

    .brand-btn {
      margin: 2rem auto;
      text-align: center;
    }
  }

  @include media-lg {
    .section {
      .header {
        width: 90%;
      }

      .gateways {
        width: 100%;
        flex-direction: column;

        ::v-deep .gateway {
          margin-top: 20px;
        }
      }
    }
  }

  @include media-sm {
    .section {
      padding: 20px;
    }
  }
}
</style>
