<template>
  <div class="gateway-container">
    <div
      @click="togglePopup"
      :class="{ active: showMoreContent }"
      class="gateway">
      <img :src="imageSrc" alt="Gateway icon">
      <div class="subsection">
        <div class="title">{{ title }}</div>
        <div class="content">{{ content }}</div>
        <button v-if="moreContent" class="brand-btn btn-transparent">
          View more
          <font-awesome-icon :icon="['fal', 'arrow-right']" />
        </button>
      </div>
    </div>
    <template v-if="showMoreContent">
      <div @click="closePopup" class="overlay"></div>
      <div @click.stop :class="{ left: popupLeft }" class="gateway-more-content">
        <button @click="closePopup" class="brand-btn btn-close" aria-label="Close popup">
          <font-awesome-icon :icon="['far', 'times']" />
        </button>
        <div class="header item">{{ moreContent.header }}</div>
        <div v-for="item in moreContent.items" :key="item.label" class="item">
          <div class="label">{{ item.label }}</div>
          <div v-if="typeof item.text === 'string'" class="text">{{ item.text }}</div>
          <template v-else>
            <div v-for="subitem in item.text" :key="subitem" class="text">{{ subitem }}</div>
          </template>
        </div>
        <div class="arrow"></div>
        <div class="arrow-conceal"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'gateway',
  props: {
    iconName: { type: String, required: true },
    title: { type: String, required: true },
    content: { type: String, required: true },
    moreContent: { type: Object, default: null },
    popupLeft: { type: Boolean, default: false }
  },
  data() {
    return {
      showMoreContent: false
    };
  },
  computed: {
    imageSrc() {
      return require(`student/assets/img/${this.iconName}`);
    }
  },
  methods: {
    closePopup() {
      this.showMoreContent = false;
      document.body.removeEventListener('click', this.closePopup);
    },
    togglePopup(event) {
      // If this popup is already open do nothing and let the click event
      // bubble up to the `this.closePopup` event listener.
      if (this.showMoreContent) return;

      // Trigger the `this.closePopup` listeners if any other gateway popup
      // is already open to close them.
      document.body.click();

      // Prevent the click event from bubbling up so that, after this popup
      // is displayed, the `this.closePopup` does not get triggered as well.
      event.stopPropagation();
      this.showMoreContent = true;
      document.body.addEventListener('click', this.closePopup);
    }
  }
};
</script>

<style lang="scss" scoped>
.gateway-container {
  position: relative;
  max-width: 100vw;
}

.gateway {
  max-width: 100%;
  width: 350px;
  height: 312px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  background-color: $brand-purple;
  color: white;
  cursor: pointer;
  transition: .2s;

  &:hover {
    background-color: $hover-state-pink;
  }

  &.active {
    background-color: $hover-state-pink;
  }

  img {
    height: 70px;
  }

  .title {
    margin-top: 20px;
    font-size: 1.5625rem;
    font-weight: 300;
  }

  .content {
    margin-top: 10px;
    width: 290px;
    height: 100px;
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }

  .brand-btn {
    margin: 0 auto;
    color: white;
    font-size: 1.1875rem;
    font-weight: 500;
    box-shadow: none;

    svg {
      margin-left: 5px;
      font-size: 1rem;
    }
  }
}

.overlay {
  display: none;
}

.gateway-more-content {
  position: absolute;
  right: -530px;
  bottom: -2px;
  width: 520px;
  padding: 40px 60px 40px 50px;
  background: white;
  border: 1px solid $brand-purple;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  box-sizing: border-box;
  z-index: 2;

  &.left {
    right: none;
    left: -530px;

    .arrow {
      left: auto;
      right: -10px;
    }

    .arrow-conceal {
      left: auto;
      right: 0;
    }
  }

  .item + .item {
    margin-top: 14px;
  }

  .btn-close {
    display: none;
  }

  .header {
    width: 367px;
    color: $text-color;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.75rem;
  }

  .label {
    color: $brand-purple;
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 2rem;
  }

  .text {
    font-size: 1.0625rem;
    line-height: 1.75rem;
  }

  .arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    left: -10px;
    background: white;
    border: 1px solid $brand-purple;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    transform: rotate(45deg);
  }

  .arrow-conceal {
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 20px;
    height: 35px;
    background: white;
  }
}

@include media-lg {
  .gateway-container {
    .overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 50;
      display: block;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, .5);
    }

    .gateway-more-content {
      position: fixed;
      z-index: 100;
      top: 20%;
      right: 10%;
      bottom: 20%;
      left: 10%;
      width: 80%;
      overflow-y: scroll;

      .btn-close {
        display: block;
        position: absolute;
        top: 15px;
        right: 10px;
      }

      .arrow,
      .arrow-conceal {
        display: none;
      }

      .header {
        margin-top: 50px;
        width: 100%;
      }
    }
  }
}

@include media-sm {
  .gateway-more-content {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
}
</style>
