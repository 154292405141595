import App from './App.vue';
import FontAwesomeIcon from 'student/plugins/font-awesome';
import router from './router';
import ValidationProvider from 'common/plugins/veevalidate';
import Vue from 'vue';
import vuetify from 'common/plugins/vuetify';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component(ValidationProvider);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
