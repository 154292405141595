<template>
  <div
    :class="{ 'alt-color': altColorScheme, 'alt-color-2': altColorScheme2, 'not-best-value': !bestValue }"
    class="package">
    <div v-if="bestValue" class="supheading">BEST VALUE</div>
    <div class="heading">{{ title }}</div>
    <div class="subtext">
      <div>{{ subtext }}</div>
      <v-btn
        v-if="viewMorePremium"
        @click="showViewMore = !showViewMore"
        text
        color="brand-purple"
        class="primary-btn view-more">
        View more
        <font-awesome-icon :icon="['fal', 'arrow-right']" class="ml-1" />
      </v-btn>
      <view-more v-if="showViewMore" @close="showViewMore = false" />
    </div>
    <package-features :features="features" />
    <template v-if="showDurationAndPriceOnPip">
      <div class="year-select">
        <cgma-dropdown
          v-model="selectedTime"
          :options="prices"
          :disabled="isTimeSelectionDisabled" />
      </div>
      <div :class="{ preview: !recommendation }" class="price-text">
        <slot></slot>
      </div>
      <package-total-price
        :selected-prices="selectedPrices"
        :recommendation="recommendation" />
    </template>
    <div v-if="recommendation" class="d-flex justify-center">
      <loader-button
        @click="selected"
        :disabled="isPackageSelectionDisabled"
        label="Select package"
        class="btn-select-package" />
    </div>
  </div>
</template>

<script>
import CgmaDropdown from 'student/components/common/CgmaDropdown';
import LoaderButton from 'student/components/common/LoaderButton';
import PackageFeatures from './Features';
import PackageTotalPrice from './TotalPrice';
import ViewMore from './ViewMore';

export default {
  name: 'package',
  props: {
    title: { type: String, required: true },
    bestValue: { type: Boolean, default: false },
    subtext: { type: String, required: true },
    viewMorePremium: { type: Boolean, default: false },
    features: { type: Object, required: true },
    priceOptions: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    recommendation: { type: Object, default: null },
    altColorScheme: { type: Boolean, default: false },
    altColorScheme2: { type: Boolean, default: false },
    isSkillsPackage: { type: Boolean, default: false },
    isPremiumPackage: { type: Boolean, default: false },
    isPlusPackage: { type: Boolean, default: false },
    showDurationAndPriceOnPip: { type: Boolean, default: false }
  },
  data() {
    return {
      selectedTime: null,
      showViewMore: false
    };
  },
  computed: {
    fromVoucher() {
      const { fromVoucher } = this.recommendation || {};
      return fromVoucher;
    },
    applicablePriceOptions() {
      const { isDisruption, isUniversity } = this.recommendation || {};
      if (isUniversity) {
        const { basic, disruption } = this.priceOptions.university;
        return isDisruption ? disruption : basic;
      }
      if (this.fromVoucher) return this.priceOptions.default;
      return this.priceOptions.default.filter(it => !it.voucherOnly);
    },
    prices() {
      const prices = this.applicablePriceOptions.map(({ time }) => ({ label: time, value: time }));
      return prices;
    },
    isTimeSelectionDisabled() {
      if (this.disabled || this.applicablePriceOptions.length === 1) return true;
      return this.fromVoucher;
    },
    isPackageSelectionDisabled() {
      return this.disabled || !this.selectedTime;
    },
    selectedOption() {
      const { applicablePriceOptions, selectedTime } = this;
      return applicablePriceOptions.find(it => it.time === selectedTime);
    },
    selectedPrices() {
      if (!this.selectedOption) return {};
      return this.selectedOption.prices;
    }
  },
  methods: {
    selected() {
      const { selectedOption, isSkillsPackage, isPremiumPackage, isPlusPackage } = this;
      const length = selectedOption.value;
      const data = { length, isSkillsPackage, isPremiumPackage, isPlusPackage };
      return this.$emit('selected', data);
    }
  },
  mounted() {
    const { recommendation, applicablePriceOptions: priceOptions } = this;
    if (!recommendation) {
      this.selectedTime = priceOptions[0].time;
    } else {
      const { subscriptionLength } = recommendation;
      const defaultExists = priceOptions.find(it => it.time === subscriptionLength);
      this.selectedTime = defaultExists ? subscriptionLength : priceOptions[0].time;
    }
  },
  components: {
    CgmaDropdown,
    LoaderButton,
    PackageFeatures,
    PackageTotalPrice,
    ViewMore
  }
};
</script>

<style lang="scss" scoped>
.package {
  margin: 0 10px;
  max-width: 100%;
  width: 16.875rem;
  text-align: center;
  box-shadow: $dark-box-shadow;

  @include media-lg {
    width: 15.625rem;
  }

  @include media-md {
    max-width: 90%;
    width: 25rem;
  }

  &.not-best-value {
    margin-top: 3.125rem;
  }

  .supheading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 1.25rem;
    font-weight: 700;
    color: white;
    background: linear-gradient(90deg, #7f4f9b 0%, #ac6593 100%);
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7.1875rem;
    padding: 1.5625rem 4rem;
    font-size: 1.9375rem;
    font-weight: 300;
    line-height: 2.3125rem;
    color: white;
    background: $purple-gradient;
    box-sizing: border-box;
  }

  .subtext {
    position: relative;
    min-height: 7.8125rem;
    padding: 7px 25px;
    font-size: 0.8125rem;
    line-height: 1.0625rem;
    color: $darker-gray;
    border: 1px solid $lighter-gray;

    @include media-md {
      min-height: unset;
    }
  }

  .year-select {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 15px 19px;
    border-left: 1px solid $lighter-gray;

    ::v-deep .select {
      display: block;
      width: 330px;
      max-width: 100%;
      height: 40px;
      padding: 0 25px;
    }
  }

  .price-text {
    padding: 0 25px 15px;
    height: 9.375rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $darker-gray;

    &.preview {
      min-height: 100px;
    }
  }

  @include media-md {
    margin-top: 1.375rem;

    .heading {
      font-size: 1.5rem;
    }
  }

  .btn-select-package {
    width: 90%;
    margin: 1.25rem 0;
  }

  &.alt-color {
    .heading {
      background: $green-custom-gradient;
    }

    .supheading {
      background: linear-gradient(90deg, #698d8a 0%, #66a361 100%);
    }

    ::v-deep {
      .select, .total-price, .currency-sign, .currency-label, .price, .select-container > svg {
        color: $influential-green;
      }

      .select-container > svg, .select, .total-price {
        border-color: $influential-green;
        background-color: #d6e8e8;
      }

      .btn-select-package:not(:disabled) {
        background-color: $influential-green;

        &:hover {
          background: #66a361;
        }
      }
    }
  }

    &.alt-color-2 {
    .heading {
      background: $blue-custom-gradient;
    }

    .supheading {
      background: linear-gradient(90deg, #5d7bbd 0%, #63c2ea 100%);
    }

    ::v-deep {
      .select, .total-price, .currency-sign, .currency-label, .price, .select-container > svg {
        color: $influential-blue;
      }

      .select-container > svg, .select, .total-price {
        border-color: $influential-blue;
        background-color: #dfe5f2;
      }

      .btn-select-package:not(:disabled) {
        background-color: $influential-blue;

        &:hover {
          background: #6279b2;
        }
      }
    }
  }
}
</style>
