<template>
  <div>
    <div @click="expanded = !expanded" class="title">
      {{ title }}
      <font-awesome-icon :icon="chevronIcon" class="chevron" />
    </div>
    <div :class="{ expanded }" class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'accordion',
  props: {
    title: { type: String, required: true }
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    chevronIcon() {
      const chevronIcon = this.expanded ? 'chevron-down' : 'chevron-right';
      return ['far', chevronIcon];
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  color: $brand-purple;
  border-bottom: 2px solid $light-gray;
  cursor: pointer;

  .chevron {
    margin-left: 10px;
    color: $dark-gray;
  }
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .2s;

  &.expanded {
    max-height: 2000px;
  }
}
</style>
