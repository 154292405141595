<template>
  <div class="section-wrapper">
    <div class="section">
      <div>
        © {{ currentYear }} Association of International Certified Professional Accountants. All rights reserved.
        This website has been developed by the AICPA and CIMA and is subject to license agreements
        between the AICPA, CIMA and the Association of International Certified Professional Accountants.
      </div>
      <div class="links">
        <a target="_blank" href="https://www.cgma.org/privacy.html">Privacy</a>
        <a target="_blank" href="https://www.cgma.org/termsandconditions.html">Terms & Conditions</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'registration-footer',
  computed: {
    currentYear() {
      const date = new Date();
      return date.getFullYear();
    }
  }
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  background-color: $darker-gray;

  .section {
    max-width: 910px;
    padding: 30px 0;
    text-align: center;
    font-size: .875rem;
    line-height: 1.375rem;
    color: white;

    .links {
      display: flex;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
        margin: 15px 0;
        height: 15px;
        padding: 0 25px;
        font-size: .9375rem;
        color: white;
        text-decoration: none;

        + a {
          border-left: 1px solid white;
        }
      }
    }
  }
}
</style>
