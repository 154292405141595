<template>
  <div class="testimonial">
    <img :alt="name" :src="imageUrl" class="image">
    <div class="background"></div>
    <div class="content-container">
      <div class="content">
        <div class="name">{{ name }}</div>
        <div class="position">
          {{ position }},
          <span class="bold">{{ company }}</span>
        </div>
        <div class="message">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'testimonial',
  props: {
    imageFilename: { type: String, required: true },
    name: { type: String, required: true },
    position: { type: String, required: true },
    company: { type: String, required: true },
    message: { type: String, required: true }
  },
  computed: {
    imageUrl() {
      return require(`student/assets/img/testimonials/${this.imageFilename}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.testimonial {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 380px;
  height: 235px;
  border: 1px solid rgb(192, 192, 192);
  border-radius: 2px 0 0 2px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.22);
  overflow: hidden;
  line-height: 1.1875rem;

  + .testimonial {
    margin-left: 32px;
  }

  .image {
    position: absolute;
    right: 0;
    height: 100%;
  }

  .background {
    position: absolute;
    margin-top: -6.25rem;
    height: 200%;
    width: 55%;
    border-radius: 50%;
    background: radial-gradient(
      circle at left,
      white 50%,
      rgba(255, 255, 255, .8) 100%
    );
  }

  .content-container {
    position: absolute;
    left: 0;
    width: 60%;
    height: 100%;
    padding-top: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    .content {
      width: 85%;
      padding-left: 10px;
      text-align: left;

      .name {
        margin-bottom: 10px;
        font-size: 1rem;
        font-weight: 500;
      }

      .position {
        margin-bottom: 10px;
        font-size: 0.8125rem;
        font-weight: 300;

        .bold {
          font-weight: 500;
        }
      }

      .message {
        font-size: 0.8125rem;
      }
    }
  }

  @include media-xlg {
    .background {
      width: 52%;
    }

    .content-container {
      .content {
        width: 85%;
        padding-left: 5px;

        .message {
          font-size: 0.75rem;
        }
      }
    }
  }

  @include media-lg {
    + .testimonial {
      margin-top: 20px;
      margin-left: 0;
    }

    .content-container {
      .content {
        padding-left: 10px;
      }
    }
  }
}
</style>
