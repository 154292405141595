import {
  faArrowLeft,
  faArrowRight,
  faAward,
  faBackpack,
  faBan,
  faBookOpen,
  faBriefcase,
  faCalculatorAlt,
  faCheck,
  faExclamationCircle,
  faCheckCircle as falCheckCircle,
  faTimes as falTimes,
  faMinus,
  faPencilAlt,
  faPlus,
  faSearch,
  faTrashAlt,
  faUserCircle,
  faUsers
} from '@fortawesome/pro-light-svg-icons';
import {
  faBars,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCheck as farCheck,
  faSquare,
  faTimes
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretDown,
  faCheckCircle,
  faCheckSquare,
  faDollarSign,
  faPoundSign,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faSpinnerThird,
  faTimesCircle
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faArrowLeft,
  faArrowRight,
  faAward,
  faBackpack,
  faBars,
  faBookOpen,
  faBriefcase,
  faCalculatorAlt,
  faCaretDown,
  faCheck,
  faCheckCircle,
  falCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCircle,
  faDollarSign,
  faExclamationCircle,
  faMinus,
  faPoundSign,
  farCheck,
  faSearch,
  faTimes,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  faUserCircle,
  faUsers,
  faTimesCircle,
  fasChevronDown,
  faSpinnerThird,
  faPencilAlt,
  faBan,
  faTrashAlt,
  faPlus,
  faSquare,
  faCheckSquare,
  falTimes
);

export default FontAwesomeIcon;
