<template>
  <div class="item develop-skills">
    <img src="../../../../assets/img/AICPA__Icon_Skills.png" alt="Tablet displaying the LMS">
    <div class="title">Develop in&#8209;demand skills</div>
    <p class="content">
      Skills That Matter — Learn highly applicable skills that 2,000 companies
      around the world have identified as being critical for driving actionable insights.
    </p>
  </div>
</template>

<script>
export default {
  name: 'develop-skills'
};
</script>
