<template>
  <div class="item">
    <img src="../../../../assets/img/AICPA__Icon_personalize.png" alt="Drawing silhouette">
    <div class="title">Personalize your learning journey</div>
    <p class="content">
      Flexible and Self-Paced — Progress at your own speed based on your previous
      knowledge and experience and on your schedule.
    </p>
  </div>
</template>

<script>
export default {
  name: 'personalized-journey'
};
</script>
