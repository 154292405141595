<template>
  <div>
    <div class="content">
      <span
        @click="currentPosition--"
        :class="{ disabled: currentPosition === 0 }"
        class="chevron chevron-left">
      </span>
      <div
        v-touch="{ left: swipeLeft, right: swipeRight }"
        class="slide-container">
        <div class="slide-wrapper" :style="`left: -${100 * currentPosition}%`">
          <slot></slot>
        </div>
      </div>
      <span
        @click="currentPosition++"
        :class="{ disabled: currentPosition === slideCount - 1 }"
        class="chevron chevron-right">
      </span>
    </div>
    <div class="navigation">
      <div
        v-for="n in slideCount"
        :key="n"
        @click="currentPosition = n - 1"
        :class="{ active: n === currentPosition + 1 }"
        class="navigation-item">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'slider',
  props: {
    slideCount: { type: Number, required: true }
  },
  data() {
    return {
      currentPosition: 0
    };
  },
  methods: {
    swipeLeft() {
      if (this.currentPosition === this.slideCount - 1) return;
      this.currentPosition++;
    },
    swipeRight() {
      if (this.currentPosition === 0) return;
      this.currentPosition--;
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .slide-container {
    width: 100%;
    overflow: hidden;

    .slide-wrapper {
      display: flex;
      position: relative;
      left: 0;
      transition: .3s;

      > div {
        width: 100%;
        position: relative;
        left: 0%;
        box-sizing: border-box;
        flex: 0 0 auto;
        padding: 0 20px;
      }
    }
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .navigation-item {
    width: 13px;
    height: 13px;
    border-radius: 100%;
    background-color: $light-gray;
    cursor: pointer;
    transition: .3s;

    &.active {
      background-color: $brand-purple;
      box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.35);
    }

    + .navigation-item {
      margin-left: 10px;
    }
  }
}

.chevron {
  transform: scale(2);
  cursor: pointer;

  &.disabled {
    color: $light-gray;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
