<template>
  <div class="sub-menu">
    <div @click="expanded = !expanded" class="label">
      {{ label }}
      <font-awesome-icon :icon="chevronIcon" class="dropdown-icon" />
    </div>
    <ul :class="{ expanded }" class="menu">
      <li v-for="link in links" :key="link.label" class="menu-item">
        <a
          @click="$emit('scrollTo', link.elementId)"
          class="menu-link">
          {{ link.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'sub-menu',
  props: {
    label: { type: String, required: true },
    links: { type: Array, required: true }
  },
  data() {
    return {
      expanded: true
    };
  },
  computed: {
    chevronIcon() {
      return this.expanded ? ['far', 'chevron-up'] : ['far', 'chevron-down'];
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-link,
.label {
  margin-right: 5px;
  font-size: 1.125rem;
  line-height: 2rem;
  color: $text-color;
  text-decoration: none;
  cursor: pointer;
}

.dropdown-icon {
  font-size: .9rem;
}

.menu {
  padding-left: 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height .2s ease-in;

  &.expanded {
    max-height: 1000px;
  }

  .menu-item {
    display: block;
    margin-bottom: 15px;
    width: 100%;
    font-weight: 300;

    &:first-child {
      margin-top: 10px;
    }

    .menu-link {
      display: block;
      width: 100%;
    }
  }
}
</style>
