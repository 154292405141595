<template>
  <div>
    <div v-if="noPackage" class="no-packages">
      Based on your location, there are no packages available to you at this time.
    </div>
    <template v-else>
      <div :class="{ recommendation, voucher: fromVoucher }" class="packages">
        <div :class="{ 'without-time-price': !showDurationAndPriceOnPip }" class="labels">
          <ul>
            <li>
              <p>Competencies</p>
              <div>
                Access to all competency areas across all levels to develop in-demand skills
              </div>
            </li>
            <li>
              <p>Business Simulations</p>
              <div>
                Test your practical application of learning using scenarios and
                solve real business problems
              </div>
            </li>
            <li>
              <p>Skills Certificates</p>
              <div>
                Earn digital certificates by completing competencies across each level
              </div>
            </li>
            <li>
              <p>Registration & Student Fees</p>
              <div>
                Get all registration and annual student subscription fees included in price
              </div>
            </li>
            <li>
              <p>Case Study Exam Review Course</p>
              <div>
                Prepare for exam with a full course concentrating on case analysis,
                exam techniques and with graded mock exams
              </div>
            </li>
            <li>
              <p>Case Study Exam Fees</p>
              <div>
                Use credits included in package to cover exam fees during your
                subscription for exams on all levels
              </div>
            </li>
            <li>
              <p>Diplomas & Designation</p>
              <div>
                Receive Diplomas or CGMA Designation on completion of each level
              </div>
            </li>
            <li>
              <p>Expert Tutor Support</p>
              <div>
                Contact a tutor for further help and support if you get stuck
              </div>
            </li>
            <li>
              <p>Live Sessions</p>
              <div>
                Attend expert led live online classes for competencies and
                Case Study preparation
              </div>
            </li>
            <li>
              <p>Student Coaching</p>
              <div>
                Get advice from personal coaches on how to best learn and progress
                through your course
              </div>
            </li>
          </ul>
          <ul v-if="showDurationAndPriceOnPip" class="time-price">
            <li>
              <span class="bold">
                <template v-if="recommendation">Your</template>
                Price
              </span>
            </li>
          </ul>
        </div>
        <package
          v-if="showSkillsPackage"
          v-on="$listeners"
          v-bind="SKILLS_PACKAGE"
          :disabled="disabled"
          :show-duration-and-price-on-pip="showDurationAndPriceOnPip"
          :recommendation="recommendation">
          <span class="bold">Skills Core</span> – develop the competencies
          you need to accelerate your career. Earn Skill Certificates to showcase
          your accomplishments. There are no exam credits or exam preparation
          materials included in this option.
          <div v-if="!recommendation">
            Subscription options may not be available in all markets.
          </div>
        </package>
        <package
          v-if="showSkillsPlusPackage"
          v-on="$listeners"
          v-bind="SKILLS_PLUS_PACKAGE"
          :disabled="disabled"
          :show-duration-and-price-on-pip="showDurationAndPriceOnPip"
          :recommendation="recommendation">
          <span class="bold">Skills Plus</span> – develop competencies to
          accelerate your career and advance your journey toward the CGMA
          Professional Qualification by preparing for and taking the case study
          exams. Two exam credits per required case study exam are included in
          this option.
        </package>
        <package
          v-if="showSkillsPremiumPackage"
          v-on="$listeners"
          v-bind="SKILLS_PREMIUM_PACKAGE"
          :disabled="disabled"
          :show-duration-and-price-on-pip="showDurationAndPriceOnPip"
          :recommendation="recommendation">
          <span class="bold">Skills Premium</span> - develop critical
          competencies, take the case study exams and advance toward CGMA with
          live, online, interactive classes and support from expert tutors. Two
          exam credits per required case study exam are included in this option.
        </package>
      </div>
      <div class="d-flex flex-column align-center disclaimer-container">
        *Price quoted does not include any local or country VAT / Taxes which will be calculated at checkout
      </div>
      <div class="d-flex flex-column align-center note-container">
        <div v-if="recommendation" class="compliance-message">
          {{ COMPLIANCE_MESSAGES[recommendation.currency] }}
        </div>
        <div class="note mb-1">
          How do I select the right subscription for me? The following guide
          includes more details to help you make the best selection.
          <div class="d-flex justify-center">
            <a
              href="https://www.aicpa-cima.com/resources/download/FLP-subscription-guide-for-students"
              target="_blank"
              class="guide-link">
              Subscription Guide
            </a>
          </div>
        </div>
        <div class="note mb-1">
          Click here for our
          <a
            href="https://www.aicpa-cima.com/resources/landing/finance-leadership-programme-faqs"
            target="_blank"
            class="guide-link">
            FAQ Guide
          </a>
          or our
          <a
            href="https://www.aicpa-cima.com/resources/download/cgma-finance-leadership-program-skills-premium-faq"
            target="_blank"
            class="guide-link">
            Skills Premium FAQ.
          </a>
        </div>
        <div class="note">
          <a href="https://beyonddisruption.libsyn.com/flp" target="_blank" class="guide-link">Listen</a>
          to the Finance Leadership Podcast.
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  COMPLIANCE_MESSAGES,
  SKILLS_PACKAGE,
  SKILLS_PLUS_PACKAGE,
  SKILLS_PREMIUM_PACKAGE
} from 'globalCommon/packages';
import Package from './Package';
import { showSkillsCoreOnPip } from 'config/shared/environment';

const SHOW_DETAILS_ON_PIP = true;

export default {
  name: 'packages',
  props: {
    disabled: { type: Boolean, default: false },
    recommendation: { type: Object, default: null }
  },
  data() {
    return {
      SKILLS_PACKAGE,
      SKILLS_PLUS_PACKAGE,
      SKILLS_PREMIUM_PACKAGE,
      COMPLIANCE_MESSAGES
    };
  },
  computed: {
    showDurationAndPriceOnPip() {
      return this.recommendation || SHOW_DETAILS_ON_PIP;
    },
    fromVoucher() {
      const { fromVoucher } = this.recommendation || {};
      return !!fromVoucher;
    },
    showSkillsPackage() {
      if (!this.recommendation) return showSkillsCoreOnPip;
      if (this.fromVoucher) return this.recommendation.isSkillsPackage;
      return this.recommendation.permissions.skillsOnly;
    },
    showSkillsPlusPackage() {
      if (!this.recommendation) return true;
      const { isSkillsPackage, isPremiumPackage } = this.recommendation;
      if (this.fromVoucher) return !isSkillsPackage && !isPremiumPackage;
      return this.recommendation.permissions.skillsPlus;
    },
    showSkillsPremiumPackage() {
      if (!this.recommendation) return true;
      if (this.fromVoucher) return this.recommendation.isPremiumPackage;
      return this.recommendation.permissions.skillsPremium;
    },
    noPackage() {
      return !this.showSkillsPackage && !this.showSkillsPlusPackage && !this.showSkillsPremiumPackage;
    }
  },
  components: {
    Package
  }
};
</script>

<style lang="scss" scoped>
.no-packages {
  display: flex;
  justify-content: center;
  margin: 7rem 4rem;
  padding: 1rem;
  color: $brand-purple;
  border: 1px solid $brand-purple;
}

.packages {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 60px auto 10px;
  width: 82.1875rem;

  .labels {
    margin-top: 18.125rem;

    &.without-time-price {
      margin-bottom: 0;
    }

    ul {
      margin: 0;
      width: 22.5rem;
      padding: 0;
      list-style: none;
      text-align: left;
      font-size: 0.875rem;
      line-height: 1rem;
      color: $text-color;

      li {
        flex-direction: column;
        align-items: flex-start !important;

        p {
          font-size: 0.875rem !important;
          font-weight: 600 !important;
          margin: 0 !important;
        }

        div {
          font-weight: 300;
        }
      }

      &:first-child {
        margin-bottom: 5px;
        height: 485px;

        li {
          display: flex;
          align-items: center;
          height: 61px;
        }
      }

      &.time-price {
        margin-top: 23.125rem;
        font-size: 1.25rem;

        li {
          margin-top: 153px;
          margin-bottom: -69px;
        }
      }
    }
  }

  &.recommendation {
    .labels {
      margin-bottom: 62px;

      ul:last-child {
        margin-bottom: 86px;

        li + li {
          margin-top: 132px;
          margin-bottom: -48px;
        }
      }
    }

    &.voucher {
      .labels {
        margin-bottom: 72px;

        ul:last-child {
          margin-top: 23px;
          margin-bottom: 85px;

          li + li {
            margin-top: 52px;
            margin-bottom: -52px;
          }
        }
      }

      ::v-deep .price-text {
        display: none;
      }
    }
  }
}

.note-container, .disclaimer-container {
  margin-left: 365px;

  @include media-xlg {
    margin-left: 0;
  }

  .compliance-message, .note {
    max-width: 850px;
    text-align: center;
    color: #6F6F6F;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .compliance-message {
    margin-bottom: 0.25rem;
  }

  .note {
    margin-bottom: 3.75rem;

    .guide-link {
      color: $brand-purple;
      font-weight: 500;
    }
  }
}

.disclaimer-container {
  margin-bottom: 25px;
  font-weight: 600;
  color: #6F6F6F;
}

@include media-xlg {
  .packages {
    width: 100%;

    .labels {
      display: none;
    }
  }

  .disclaimer-container {
    width: 100%;
    text-align: center;
  }

  .note-container {
    width: 100%;

    .compliance-message, .note {
      width: 100%;
    }

    .note {
      margin: 0 auto 60px auto;
    }
  }
}

@include media-md {
  .packages {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    height: auto;
  }
}
</style>
