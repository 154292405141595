'use strict';

const { SKILLS_PACKAGE, SKILLS_PLUS_PACKAGE, SKILLS_PREMIUM_PACKAGE } = require('./skills');
const COMPLIANCE_MESSAGES = require('./complianceMessages');
const CPA_PACKAGES = require('./cpa');

module.exports = {
  COMPLIANCE_MESSAGES,
  CPA_PACKAGES,
  SKILLS_PACKAGE,
  SKILLS_PLUS_PACKAGE,
  SKILLS_PREMIUM_PACKAGE
};
