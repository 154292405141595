<template>
  <div class="select-container">
    <font-awesome-icon
      v-if="!disabled"
      :icon="['fas', 'chevron-down']"
      class="icon" />
    <select
      @change="$emit('input', $event.target.value)"
      :value="value"
      :class="{ 'default-selected': !value }"
      :disabled="disabled"
      class="select">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'cgma-dropdown',
  props: {
    value: { type: String, default: null },
    options: { type: Array, required: true },
    disabled: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
.select-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  &:after {
    content: "";
    position: absolute;
    top: .3em;
    right: .75em;
    display: none;
    border-top: 8px solid black;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    width: 0;
    height: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  .icon {
    position: absolute;
    right: 5%;
    top: 0.9375rem;
    font-size: 0.9rem;
    color: $brand-purple;
    pointer-events: none;
  }

  .select {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    border: 1px solid $brand-purple;
    color: $brand-purple;
    background-color: $pink;
    appearance: none;

    &.default-selected {
      font-style: italic;
    }

    &:disabled {
      opacity: 1;
      text-align-last: center;
    }

    option {
      font-style: normal;

      &[value=""] {
        font-style: italic;
      }
    }
  }
}
</style>
