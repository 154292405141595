<template>
  <div id="employersAndUniversities" class="section-wrapper">
    <div class="section">
      <div class="item">
        <h2>Employers: advance your staff and strengthen your business</h2>
        <p class="emphasized">Empower your team to become strategic finance leaders.</p>
        <div class="content">
          <p>
            Trusted by companies worldwide and designed specifically for finance professionals, the CGMA
            Finance Leadership Program supports your finance team to develop competencies in operations,
            management and strategy while they support your business needs. Case studies and real world
            business simulations enable learners to
          </p>
          <p>
            deliver results for your business while they expand their knowledge. Team leaders can review
            staff progress and align employees’ competency-based learning with business needs. The Finance
            Leadership Program also prepares finance teams to earn the prestigious CGMA designation.
          </p>
        </div>
        <p class="emphasized">
          Featured partners
        </p>
        <div class="employer-logos">
          <img src="../../assets/img/employers/GeorgiaPacific.png" alt="Georgia Pacific logo">
          <img src="../../assets/img/employers/Volvo.png" alt="Volvo logo">
          <img src="../../assets/img/employers/Koch.png" alt="Koch logo">
          <img src="../../assets/img/employers/Siemens.png" alt="Siemens logo">
          <img src="../../assets/img/employers/Molex.png" alt="Molex logo">
        </div>
        <div class="employer-logos">
        </div>
      </div>
      <div class="item">
        <h2>Universities: give your students real world skills and insight</h2>
        <p class="emphasized">Connect the classroom to the workplace.</p>
        <div class="content">
          <p>
            The CGMA Finance Leadership Program teaches students the skills needed to become successful
            financial professionals ahead of their joining the profession. Paired with undergraduate or
            graduate courses, the program uses real world scenarios and competency based assessments. A
            curriculum organized around operations,
          </p>
          <p>
            management, and strategy will enhance their studies and help them form goals for their
            eventual contributions to the finance field. The program also prepares students to earn
            the CGMA designation. With this professional qualification alongside their degree,
            students will stand out to potential employers upon graduation.
          </p>
        </div>
        <div class="partner-section">
          <a
            target="_blank"
            href="https://www.cgma.org/aboutus/academic-partners.html"
            class="brand-btn btn-prominent btn-purple btn-rounded">
            Become a partner
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'employers-universities'
};
</script>

<style lang="scss" scoped>
.section {
  padding-left: 155px;

  .item {
    margin: 50px 0;

    h2 {
      margin-bottom: 1.25rem;
    }
  }

  .content {
    display: flex;
    font-size: 1.0625rem;
    line-height: 2rem;

    p {
      width: 550px;

      + p {
        margin-left: 40px;
      }
    }
  }

  .employer-logos {
    img {
      margin-bottom: 30px;

      + img {
        margin-left: 50px;
      }
    }
  }

  @include media-lg {
    padding: 10px 20px;

    .employer-logos {
      text-align: center;
    }

    .partner-section {
      display: flex;
      justify-content: center;
    }
  }

  @include media-sm {
    .item {
      .content {
        flex-direction: column;

        p {
          width: 100%;

          + p {
            margin: 0;
          }
        }
      }

      .employer-logos {
        img {
          display: block;
          margin: 40px auto;
        }
      }
    }

    .brand-btn {
      margin-top: 20px;
    }
  }
}
</style>
