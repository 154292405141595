import request from 'common/api/request';

const ROOT_PATH = 'country-affiliation';

const paths = {
  check: `${ROOT_PATH}/is-supported`
};

export function isSupported(code) {
  return request.get(paths.check, { params: { code } }).then(({ data }) => data);
}
